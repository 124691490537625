<template>
  <div>
    <form aria-live="polite" class="settings__form">
      <label class="field clearfix">
        <span class="field__label">
          {{ $t('account-information.email-address') }}
        </span>
        <input
          class="field__input"
          type="email"
          id="serial-number"
          v-model.trim="fields.email"
          v-on:keydown.enter.prevent="buttonClick"
        />
          <span class="field__error"></span>
      </label>

      <footer class="settings__form__footer clearfix">
        <button-action
          :disabled="!validEmail || isLoading"
          :isLoading="isLoading"
          :isVisible="true"
          id="js-profile-save-button"
          @click='buttonClick'
        >
          {{$t('account-information.search')}}
        </button-action>
      </footer>
    </form>

    <form
      v-if="responseReceived"
      aria-live="polite"
      class="settings__form"
      id="hub__response__form"
    >
      <template v-if="customerEmail">
        <div class="account-information">
          <p>
            {{ $t('account-information.verify-email') }}
            <br>
          </p>
          <dl>
            <dt>{{ $t('account-information.full-name') }}</dt>
            <dd>{{ customerFirstName }} {{ customerLastName }}</dd>

            <dt>{{ $t('account-information.migration-status') }}</dt>
            <dd>{{ customerMigrationState }}</dd>

            <dt>{{ $t('account-information.last-seen') }}</dt>
            <dd>{{ customerLastSeen }}</dd>

            <dt>{{ $t('account-information.registration') }}</dt>
            <dd>{{ customerRegistration }}</dd>

            <dt>{{ $t('account-information.language') }}</dt>
            <dd>{{ customerLanguage }}</dd>

            <dt>{{ $t('account-information.confirmed') }}</dt>
            <dd>{{ customerConfirmed }}</dd>

            <dt v-if="isMaintainer">{{ $t('account-information.account-id') }}</dt>
            <dd v-if="isMaintainer">{{ customerAccount }}</dd>

            <dt v-if="isBoschAccount">{{ $t('account-information.obc-id') }}</dt>
            <dd v-if="isBoschAccount">{{ customerObcId }}</dd>

            <dt v-if="isBoschAccount">{{ $t('account-information.bosch-id') }}</dt>
            <dd v-if="isBoschAccount">{{ customerBoschId }}</dd>
          </dl>
        </div>
        <!-- Devices Table -->
        <devices-information :devices="customer.devices"></devices-information>
        
        <!-- Hubs table -->
        <div class="hubs-container">
          <div class="table-header">Bike Information</div>
          <div class="hubs-row hubs-header">
            <div class="hubs-field hubs-serial-number">
              {{ $t('account-information.hubs.serial-number') }}
            </div>
            <div class="hubs-field hubs-part-number">
              {{ $t('account-information.hubs.part-number') }}
            </div>
            <div class="hubs-field hubs-type">
              {{ $t('account-information.hubs.type') }}
            </div>
            <div class="hubs-field hubs-role">
              {{ $t('account-information.hubs.role') }}
            </div>
            <div class="hubs-field hubs-activation-time">
              {{ $t('account-information.hubs.activation-time') }}
            </div>
            <div class="hubs-field hubs-sharing-mode">
              {{ $t('account-information.hubs.sharing-mode') }}
            </div>
            <div class="hubs-field hubs-firmware">
              {{ $t('account-information.hubs.firmware') }}
            </div>
          </div>
          <div
            class="hubs-row"
            v-for="hub in customer.hubs"
            :key="hub.id"
          >
            <div class="hubs-field hubs-serial-number">
              {{ hub.serialNumber }}
            </div>
            <div class="hubs-field hubs-part-number">
              {{ hub.partNumber || '-' }}
            </div>
            <div class="hubs-field hubs-type">
              {{ $t('hub_table.device_types.' + hub.hubType) || '-' }}
            </div>
            <div class="hubs-field hubs-role">
              <img
                v-if="hub.role === 'owner'"
                class="hubs-icon"
                :alt="$t('hub_table.owner_alt')"
                :title="$t('hub_table.owner_title')"
                src="../../common/assets/images/svg/owner.svg"
              />
              <img
                v-if="hub.role === 'guest'"
                class="hubs-icon"
                :alt="$t('hub_table.guest_alt')"
                :title="$t('hub_table.guest_title')"
                src="../../common/assets/images/svg/guest.svg"
              />
            </div>
            <div class="hubs-field hubs-activation-time">
              {{ hub.activationTime || '-' }}
            </div>
            <div class="hubs-field hubs-sharing-mode">
              <img
                v-if="hub.sharingMode === 'public'"
                class="hubs-icon"
                :alt="$t('hub_table.sharing_mode_on_alt')"
                :title="$t('hub_table.sharing_mode_on_title')"
                src="../../common/assets/images/svg/sharing-mode-on.svg"
              />
              <img
                v-if="hub.sharingMode === 'off'"
                class="hubs-icon"
                :alt="$t('hub_table.sharing_mode_off_alt')"
                :title="$t('hub_table.sharing_mode_off_title')"
                src="../../common/assets/images/svg/sharing-mode-off.svg"
              />
            </div>
            <div class="hubs-field hubs-firmware">
              {{ hub.firmwareVersion || '-' }}
            </div>
          </div>
          <div
            class="hubs-row no-entries"
            v-if="!customer.hubs || customer.hubs.length === 0"
          >
            {{ $t('account-information.hubs.no-hubs-connected') }}
          </div>
        </div>
      </template>
      <user-actions
        v-if="responseReceived"
        :user="user"
        :customer="customer"
        :isMaintainer="isMaintainer"
        :isBoschAccount="isBoschAccount"
        @resetCustomerData="onResetCustomer">
      </user-actions>
    </form>
  </div>
</template>

<script>
  import auth from '../../common/auth';
  import formatDate from '../../common/utils/formatDate';
  import UserActions from './UserActions.vue';
  import ButtonAction from './ButtonAction.vue';
  import emitter from '../../utils/emitter';

  import devicesInformation from './DeviceInformation.vue';

  const logger = console;

  export default {
    name: 'user-data',
    components: {
      'user-actions': UserActions,
      'button-action': ButtonAction,
      'devices-information': devicesInformation,
    },
    data() {
      return {
        user: auth.user,
        fields: {
          email: '',
        },
        responseReceived: false,
        isLoading: false,
        customer: {},
        isMaintainer: false,
      };
    },
    computed: {
      validEmail() {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.fields.email);
      },
      isBoschAccount() {
        // eslint-disable-next-line camelcase
        return this.customer.user?.migration_state !== 'not_migrated';
      },
      // NOTE: optional chaining is not supported in Vue templates only from v3
      customerEmail() {
        return this.customer.user?.email;
      },
      customerFirstName() {
        return this.customer.user?.firstname;
      },
      customerLastName() {
        return this.customer.user?.lastname;
      },
      customerMigrationState() {
        // eslint-disable-next-line camelcase
        return this.customer.user?.migration_state;
      },
      customerLastSeen() {
        return this.customer.user?.lastseen;
      },
      customerRegistration() {
        return this.customer.user?.registration;
      },
      customerLanguage() {
        return this.customer.user?.language;
      },
      customerConfirmed() {
        return this.customer.user?.confirmed;
      },
      customerAccount() {
        return this.customer.user?.account;
      },
      customerObcId() {
        return this.customer.user?.obcId;
      },
      customerBoschId() {
        return this.customer.user?.boschId;
      },
    },
    methods: {
      async buttonClick() {
        this.isLoading = true;

        try {
          const userResponse = await auth.doAuthenticatedPost(
            'customerservice/user/search',
            {
              email: this.fields.email,
            }
          );
          this.isLoading = false;

          if (userResponse.data.user.length === 0) {
            emitter.emit('display-error-message', this.$i18n.t('alerts.no-user-found'));
            return;
          }

          this.customer = userResponse.data;

          if (this.customer.hubs.length > 0) {
            this.customer.hubs.map((hub) => {
              const d = new Date(hub.activationTime);
              // eslint-disable-next-line no-param-reassign
              hub.activationTime = formatDate(d);
              return hub;
            });
          }
          
          this.showMigrationOption = userResponse.data.user.migration_state === 'migrated' && this.user.roles.includes('maintainer');
          this.isMaintainer = this.user.roles.includes('maintainer');
          this.responseReceived = true;
        } catch (error) {
          logger.log(error);
          this.isLoading = false;
          emitter.emit('display-error-message');
        }
      },

      onResetCustomer() {
        this.customer = {};
        this.responseReceived = false;
      },
    },
  };
</script>

<style scoped>
  .table-header {
    padding-left: 15px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .hubs-header .hubs-field {
    padding: 20px 5px;
    background-color: rgb(243, 243, 243);
  }

  .settings__form__footer {
    margin-top: 40px;
  }

  #hub__response__form {
    padding: 2em 0 0 0;
    background-color: white;
    margin-top: 3em;
  }

  #hub__response__form .button {
    margin-left: .5em;
    width: initial;
  }

  #hub__response__form .button.loading .button-label {
    opacity: .8;
  }

  @media all and (max-width: 480px) {
    .hub__date-added {
      clear: both;}
    .settings__form {
      margin-top: 10px;
      margin-left: 5px;
      margin-right: 5px;}
    .form__title {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
  @media all and (max-width: 640px) {
    .landing__content {
      margin-top: 40px;
      width: 95%;
      padding-left: 5px;
    }
  }
</style>

<style scoped>
  .account-information, .hub-container {
    max-width: 70ch;
    margin: 0 auto;
  }

  .account-information {
    margin-bottom: 2em;
  }

  .account-information p {
    text-align: left;
  }
  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  dt {
    font-weight: bold;
  }

  .hubs-container {
    padding: 0 2em;
    margin-bottom: 2em;
  }

  .hubs-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .hubs-row.no-entries {
    justify-content: center;
  }

  .hubs-row:nth-of-type(even) {
    background-color: rgb(243, 243, 243);
  }

  .hubs-field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 5px;
  }

  .hubs-field:first-of-type {
    justify-content: flex-start;
    padding-left: 15px;
  }

  .hubs-serial-number {
    flex: 8;
  }

  .hubs-part-number {
    flex: 8
  }

  .hubs-type {
    flex: 11
  }

  .hubs-role {
    flex: 3
  }

  .hubs-activation-time {
    flex: 5
  }

  .hubs-sharing-mode {
    flex: 7
  }

  .hubs-firmware {
    flex: 5
  }

  .hubs-icon {
    width: 25px;
    height: 25px;
  }
</style>
