module.exports = {
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.API_URL,
  WEB_URL: process.env.WEB_URL,
  HOST: process.env.CUSTOMER_SERVICE_HOST,
  PORT: process.env.PORT,
  FEATURE_ENABLE_BOSCH_ID: process.env.FEATURE_ENABLE_BOSCH_ID || false,
  LANGUAGES: (process.env.LANGUAGES || 'en').split(','), // we use split because env keys don't support arrays
  OBC_KC_LOGOUT_URI: process.env.OBC_KC_LOGOUT_URI,
  OBC_KC_REDIRECT_URI: process.env.OBC_KC_REDIRECT_URI,
  PHRASE_API_TOKEN: process.env.PHRASE_API_TOKEN,
}
