<template>
  <Modal v-on:close="$emit('close')" data-testid="promotion-texts-modal">
    <template v-slot:header>
      <h3>{{ `${$t('help_connect.edit_promotion_texts_heading')} ${countryCode}` }}</h3>
    </template>
    <template v-slot:body>
      <Tabs :items="tabItems">
        <template v-slot:tab-nav-item="{ tabName, isActive }">
          <div
            class="tab-nav-item"
            :class="{ 'is-active': isActive }"
            :data-testid="`${tabName}-tab`"
          >
            {{ tabName }}
          </div>
        </template>
        <TabContent
          v-for="lang in langs"
          :key="lang"
          :name="lang"
          :selected="selectedTab === lang"
          :data-testid="`${lang}-tab-content`"
        >
          <div
            v-for="(textValue, textElement) in texts[lang]"
            :key="textElement"
            :data-testid="`${textElement}-input`"
          >
            <label :for="textElement">{{ $t(`help_connect.promotion_${textElement}`) }}</label>
            <template v-if="textElement !== 'text' && textElement !== 'consent'">
              <input class="text-input" type="text" v-model="texts[lang][textElement]" />
            </template>
            <template v-else>
              <textarea class="text-input" v-model="texts[lang][textElement]"></textarea>
            </template>
          </div>
        </TabContent>
      </Tabs>
    </template>
    <template v-slot:footer>
      <div class="footer">
        <button data-testid="save-button" class="button button--filled--blue" @click="onSave">
          {{ $t('help_connect.save') }}
        </button>
        <button data-testid="cancel-button" class="button cancel" @click="$emit('close')">
          {{ $t('help_connect.cancel') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
  import Modal from '../../common/components/Modal.vue';
  import Tabs from '../../common/components/Tabs.vue';
  import TabContent from '../../common/components/TabContent.vue';
  import auth from '../../common/auth';
  import emitter from '../../utils/emitter';

  export default {
    components: {
      Modal,
      Tabs,
      TabContent,
    },
    props: ['promotionTexts', 'defaultSelectedLanguage', 'countryCode'],
    emits: ['close'],
    data() {
      return {
        texts: { ...this.promotionTexts },
        selectedTab: this.defaultSelectedLanguage,
      };
    },
    watch: {
      promotionTexts(newTexts) {
        this.texts = { ...newTexts };
      },
    },
    methods: {
      async onSave() {
        try {
          const res = await auth.doAuthenticatedPut(
            `hc-terms-and-conditions/promotion/${this.countryCode}`,
            this.texts
          );
          emitter.emit('display-success-message', res.data);
          this.$emit('close');
          window.scrollTo(0, 0);
        } catch (err) {
          emitter.emit('display-error-message', err);
          this.$emit('close');
        }
      },
      selectTab(tab) {
        this.selectedTab = tab;
      },
    },
    computed: {
      langs() {
        return Object.keys(this.texts);
      },
      tabItems() {
        // TODO: make this dynamic
        return this.langs.map((lang) => ({ name: lang, isActive: this.selectedTab === lang }));
      },
    },
    mounted() {
      emitter.on('select-hc-countries-tab', this.selectTab);
    },
  };
</script>

<style scoped>
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.cancel {
  margin-left: 10px;
}
.text-input {
  width: 100%;
  padding: 10px;
  font-family: inherit;
  border: 1px solid #000;
  border-radius: 5px;
}

textarea {
  resize: none;
  height: 120px;
  font-family: inherit;
}

textarea.text-input-consent {
  height: 100px;
}

label {
  text-transform: capitalize;
}

h3 {
  padding: 0;
}

.tab-nav-item {
  width: 50px;
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-nav-item.is-active {
  color: #fff;
  background: #00c8e6;
}
</style>
