<template>
  <div>
    <slot
      :title="$t('notfound.page-title')"
    ></slot>
    <div class="content-wrapper">
      <div class="landing__container clearfix">
        <div class="landing__content">
          <h1>{{ $t('notfound.headline') }}</h1>
          <p>{{ $t('notfound.text1') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'not-found',
  };
</script>

<style>
  .landing__container {
    width: 90%;
    margin: 40px auto;
    font-size: 18px;
    font-family: Helvetica, Arial, sans-serif;
  }
  .UniSansBook-loaded .landing__container {
    font-family: 'UniSansBook';
  }
  .landing__container .field {
    margin: 30px 0 40px;
  }
  .landing__container a{
    color: #00c8e6;
  }

  @media all and (min-width: 640px) {
    .landing__container {
      width: 75%;
      margin: 100px auto;
    }
    .landing__container .landing__content h1 {
      font-size: 36px;
    }
  }
  @media all and (min-width: 1024px) {
    .landing__container {
      width: 60%;
    }
    .landing__container .landing__content {
      width: 80%;
      float: left;
    }
  }
  @media all and (min-width: 1300px) {
    .landing__container .landing__content {
      width: 60%;
      float: left;
    }
  }
</style>
