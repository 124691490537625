<template>
  <div>
    <slot :title="$t('build-information.title')"></slot>
    <div class="wrapper">
      <div class="content">
        <table>
          <tr>
            <td>{{ $t('build-information.version') }}</td>
            <td>{{ version }}</td>
          </tr>
          <tr>
            <td>{{ $t('build-information.build-date') }}</td>
            <td>{{ buildDate }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import buildInformation from '../../buildInformation.json';

  export default {
    name: 'build-information',
    computed: {
      version() {
        return buildInformation.version;
      },
      buildDate() {
        return buildInformation.buildDate;
      },
    },
  };
</script>

<style scoped>
    .wrapper {
        background-color: #fff;
        margin-top: 110px;
        min-height: 800px;
    }
    .content {
        background-color: #f3f3f3;
        margin: 20px auto;
        padding: 20px;
        display: flex;
        justify-content: center;
    }
    .content table td {
        padding: 0 20px;
    }
</style>
