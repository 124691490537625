<template>
  <div>
    <entries-input
      v-if="shouldShowPagination"
      v-model="entriesPerPage"
      :rowsLength="rows.length"
      :totalEntries="totalEntries"
      @updateNumOfEntries="updateNumOfEntries"
    ></entries-input>

    <div class="table-container" v-if="rows.length !== 0 && !loading">
      <div class="row header-row">
        <div
          class="cell header-cell"
          v-for="column in columns"
          :key="column.name"
          :class="[column.class, column.headerInfo ? 'with-info' : '']"
        >
          {{column.name}}

          <div v-if="column.info" @click="toggleInfo" v-click-outside="hideInfo" class="header-cell-info">
            <img
              src="../assets/images/svg/info.svg"
            >
          </div>

          <div class="header-info-content">
            <div v-html="column.info"></div>
          </div>
        </div>

        <slot name="actionsHeader"></slot>
      </div>

      <div class="table-body">
        <div class="row-container" v-for="row in rows" :key="row.id">
          <div class="row" :class="[row.id, highlightedRowId && row.id === highlightedRowId ? 'highlighted-row' : '']">
            <div
              class="cell"
              v-for="column in columns"
              :key="column.name"
              :class="[column.class, column.accessor]"
            >
              {{renderCell(column, row)}}
              <slot v-if="column.slotName" :name="column.slotName" :row="row" :column="column"></slot>
            </div>

            <slot name="actionsBody" :row="row"></slot>
          </div>

          <slot name="customRowActions" :row="row"></slot>
        </div>
      </div>
    </div>
    <div
      class="table-notification loading"
      v-if="loading"
    >
      <div>
        <infinity-loader width="60"></infinity-loader>
      </div>
      <p>{{ loadingText || 'Fetching Data' }}</p>
    </div>

    <div
      class="table-notification no-results"
      v-if="rows.length === 0 && !loading"
    >
      <p>{{ noResultsText || 'No Results' }}</p>
    </div>
    <pagination
      v-if="shouldShowPagination"
      v-model="page"
      :totalPageCount="totalPageCount"
      @updatePage="updatePage"
    >
    </pagination>
  </div>
</template>

<script>
  import InfinityLoader from './InfinityLoader.vue';
  import Pagination from './table/Pagination.vue';
  import EntriesInput from './table/EntriesInput.vue';

  export default {
    name: 'generic-table',
    props: [
      'rows',
      'loading',
      'columns',
      'noResultsText',
      'loadingText',
      'highlightedRowId',
      // Pagination Props
      'totalEntries',
      'showPagination',
      'initialPageNumber',
      'initialEntriesPerPage',
    ],
    emits: [
      'getPage',
    ],
    components: {
      'infinity-loader': InfinityLoader,
      pagination: Pagination,
      'entries-input': EntriesInput,
    },
    data() {
      return {
        page: this.initialPageNumber || 1,
        entriesPerPage: this.initialEntriesPerPage || 15,
      };
    },
    methods: {
      renderCell(column, row) {
        if (column.slotName) {
          return;
        }

        if (column.render) {
          return column.render(row);
        }

        return row[column.accessor] || column.defaultValue;
      },
      updatePage(newPage) {
        this.page = newPage;

        this.$emit('getPage', newPage, this.entriesPerPage);
      },
      updateNumOfEntries(newEntriesPerPage) {
        this.page = 1;
        this.entriesPerPage = newEntriesPerPage;

        this.$emit('getPage', this.page, this.entriesPerPage);
      },
      toggleInfo(e) {
        e.currentTarget.nextElementSibling?.classList?.toggle('visible');
      },
      hideInfo(_, currentTarget) {
        currentTarget.nextElementSibling?.classList?.remove('visible');
      },
    },
    computed: {
      totalPageCount() {
        return Math.ceil((this.totalEntries || 1) / this.entriesPerPage);
      },
      shouldShowPagination() {
        return this.rows.length && !this.loading && this.showPagination;
      },
    },
  };
</script>

<style scoped>
  .table-body .row-container:nth-of-type(even)  {
    background-color: rgb(243, 243, 243);
  }

  .table-body .row-container  {
    background-color: #fff;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    transition: background-color .2s linear;
    font-weight: 300;
  }

  .header-row {
    font-weight: bold;
    background-color: rgb(243, 243, 243);
    position: relative;
  }

  :deep(.cell) {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .header-cell-info {
    color: #1c1b1ce3;
    font-weight: 500;
    margin-left: 4px;
    cursor: pointer;
  }

  .header-cell-info img {
    width: 12px;
    height: 12px;
  }

  .header-info-content {
    visibility: hidden;
    position: absolute;
    background-color: #fff;
    min-width: 200px;
    min-height: 40px;
    padding: 20px;
    top: 80%;
    box-shadow: 0 0 3px 1px #12121259;
    border-radius: 8px;
  }

  .header-info-content.visible {
    visibility: visible;
  }

  .cell:first-child {
    padding-left: 25px;
  }

  :deep(.cell:last-child) {
    padding-right: 25px;
  }

  :deep(.actions-field) {
    justify-content: flex-end;
  }

  .table-notification {
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 20px;
    clear: both;
    box-shadow: inset 0 0 2px #e3e3e3;
  }

  .highlighted-row {
    background-color: #2d2d37;
    color: #fff;
    font-weight: 600;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  :deep(.flex-3) {
    flex: 3;
  }

  .flex-4 {
    flex: 4;
  }

  .flex-5 {
    flex: 5;
  }

  .flex-6 {
    flex: 6;
  }

  .flex-7 {
    flex: 7;
  }

  .flex-8 {
    flex: 8;
  }

  .flex-9 {
    flex: 9;
  }

  .flex-10 {
    flex: 10;
  }

  .flex-11 {
    flex: 11;
  }

  .flex-12 {
    flex: 12;
  }
</style>

<style>
  /** Here are the styles for Entries and pagination input */
  .table-pagination-input::-webkit-outer-spin-button,
  .table-pagination-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  .table-pagination-input {
      -moz-appearance: textfield;
  }

  .table-pagination-input {
    width: 45px;
    height: 30px;
    margin-left: 5px;
    padding: 5px 5px 0px;
    font-size: 1.125rem;
    line-height: 1.6;
    font-weight: 300;
    text-align: end;
    background-color: transparent;
  }
</style>
