import { setCookie, getCookie, eraseCookie } from '../utils/handleCookies';
/**
 * Sets the auth tokens in localstorage
 *
 * @param {string} accessToken
 * @param {string} refreshToken
 */

export const setAuthTokens = (accessToken, refreshToken) => {
  if (accessToken) {
    setCookie('accessToken', accessToken, 2);
  }
  if (refreshToken) {
    setCookie('refreshToken', refreshToken, 2);
  }
};

/**
 * @typedef {Object} AuthTokens
 * @property {string} accesToken
 * @property {string} refreshToken
 */
/**
 * Gets the auth tokens from localstorage
 *
 * @return {AuthTokens}
 */

export const getAuthTokens = () => ({
  accessToken: getCookie('accessToken'),
  refreshToken: getCookie('refreshToken'),
});

export const removeAuthTokens = () => {
  eraseCookie('accessToken');
  eraseCookie('refreshToken');
};
