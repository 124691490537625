import axios from 'axios';
import config from '../../../config';
import { getAuthTokens, setAuthTokens } from '../auth/accessToken';

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (axiosConfig) => {
    const updatedConfig = { ...axiosConfig };
    const { accessToken } = getAuthTokens();
    updatedConfig.headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    };

    return updatedConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

export async function refreshAccessToken() {
  const { refreshToken: currentRefreshToken } = getAuthTokens();
  try {
    const res = await axios({
      method: 'post',
      url: `${config.API_URL}user/getTokens`,
      data: {
        refreshToken: currentRefreshToken,
      },
    });
    return {
      accessToken: res.data.access_token,
      refreshToken: res.data.refresh_token,
      idToken: res.data.id_token,
    };
  } catch (err) {
    console.log('Error refreshing tokens', err);
    return null;
  }
}

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.isRetry) {
      originalRequest.isRetry = true;
      const { accessToken, refreshToken } = await refreshAccessToken();
      setAuthTokens(accessToken, refreshToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
