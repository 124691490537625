<template>
  <div id="app">
    <cobi-header
      v-bind:navigation="[
        {
          translation: 'firmware.headline',
          link: '/firmware',
          requireAuthentication: true,
          role: 'firmware_manager',
        },
        {
          translation: 'account-information.headline',
          link: '/account-information',
          requireAuthentication: true,
          role: 'cobi',
        },
        {
          translation: 'hubstatus.headline',
          link: '/hubstatus',
          requireAuthentication: true,
          role: 'cobi',
        },
        {
          translation: 'view-logs.headline',
          link: '/view-logs',
          requireAuthentication: true,
          role: 'team',
        },
        {
          translation: 'oem.header',
          link: '/oem',
          requireAuthentication: true,
          role: 'oem',
        },
        {
          translation: 'COBI Countries', //'help_connect.heading',
          link: '/cobi-countries',
          requireAuthentication: true,
          role: 'active_countries',
        },
        {
          translation: 'Admin',
          link: '/admin',
          requireAuthentication: true,
          role: 'maintainer',
        },
      ]"
      v-bind:link-to-account="false"
    ></cobi-header>
    <div class="wrapper content-container clearfix" id="content-container">
      <router-view v-slot="{ Component }">
        <component :is="Component">
          <template v-slot:default="slotProps">
            <div class="content-header_padding">
              <div id="content-header_wrapper">
                <success-notification
                  v-if="slotProps.withSuccess"
                  :success="slotProps.success"
                  :controlled="slotProps.controlled"
                  :withTranslation="slotProps.withTranslation"
                ></success-notification>
                <error-notification
                  v-if="slotProps.withError"
                  :error="slotProps.error"
                  :controlled="slotProps.controlled"
                  :withTranslation="slotProps.withTranslation"
                ></error-notification>
                <header class="content-header content-header--settings clearfix" id="content-header">
                  <h1 class="page-title">
                    {{ slotProps.title }}
                  </h1>
                </header>
              </div>
            </div>
          </template>
        </component>
      </router-view>
    </div>
    <cobi-footer
      v-bind:show-footnote="false"
      v-bind:is-bosch-id-enabled="isBoschIdEnabled"
    ></cobi-footer>
  </div>
</template>

<script>
  import { FEATURE_ENABLE_BOSCH_ID } from '../config/index';
  import SuccessNotification from './common/components/SuccessNotification.vue';
  import ErrorNotification from './common/components/ErrorNotification.vue';
  import emitter from './utils/emitter';

  export default {
    name: 'app',
    components: {
      'success-notification': SuccessNotification,
      'error-notification': ErrorNotification,
    },
    computed: {
      isBoschIdEnabled() {
        return FEATURE_ENABLE_BOSCH_ID;
      },
    },
    methods: {
      displaySuccessMessage(text) {
        const successAlertText = document.getElementById('success-alert-text');
        if (!text) {
          successAlertText.textContent = this.$i18n.t('alerts.success');
        } else {
          successAlertText.textContent = text;
        }

        const alertSuccess = document.getElementsByClassName('alert--success')[0];
        alertSuccess.classList.add('show');

        setTimeout(() => {
          alertSuccess.classList.remove('show');
        }, 3000);
      },
      displayErrorMessage(text) {
        const errorAlertText = document.getElementById('error-alert-text');
        if (!text) {
          errorAlertText.textContent = this.$i18n.t('alerts.error');
        } else {
          errorAlertText.textContent = text;
        }

        const alertError = document.getElementsByClassName('alert--error')[0];
        alertError.classList.add('show');

        setTimeout(() => {
          alertError.classList.remove('show');
        }, 3000);
      },
    },
    mounted() {
      emitter.on('display-success-message', this.displaySuccessMessage);
      emitter.on('display-error-message', this.displayErrorMessage);
    },
  };
</script>

<style>
@import './common/css/fonts.css';
@import './common/css/general.css';
@import './common/css/button.css';
@import './common/css/global.css';
@import './common/css/flex.css';
</style>
