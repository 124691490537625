<template>
  <div>
    <slot
      :error="error"
      :controlled="true"
      :withError="true"
      :title="$t('login.page-title')"
    >
    </slot>
    <div class="content-wrapper">
      <div class="login__container clearfix">
        <div class="bosch-login">
          <h1 class="bosch-login--title">
            {{ $t('login.bosch_id.title') }}
          </h1>
          <p v-html="$t('login.singlekey_id.description')"></p>
          <button
            type="button"
            class="button button--ghost--tarmac spinning-button bosch-login--login-button"
            @click="loginWithBosch()"
            data-test-id="login-with-bosch-id"
          >
            {{ $t('login.singlekey_id.login_with_singlekey_id') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import config from '../../../config';

  export default {
    name: 'bosch-login',
    data() {
      return {
        error: this.$route.query.title,
      };
    },
    methods: {
      loginWithBosch() {
        const successRedirectPath = this.$route.query.redirect || '/';
        // Redirect back to my/cs.cobi.bike after succesfull OBC login
        const successRedirectUri = new URL(successRedirectPath, window.location.origin);
        // Redirect to login screen again if OBC login failed
        // (query parameters containing more information wil be appended)
        const errorRedirectUri = new URL('/login', window.location.origin);

        // Construct final uri that contains success- and error- redirect uri
        const boschLoginEndpoint = `/user/weblogin/?returnTo=${encodeURIComponent(successRedirectUri)}&redirectUri=${encodeURIComponent(errorRedirectUri)}`;
        const loginUri = new URL(boschLoginEndpoint, config.API_URL);

        window.location.href = loginUri;
      },
    },
  };
</script>

<style>
  .bosch-login {
    background-image: url('../assets/images/bosch/bosch-supergraphic.png');
    background-size: 100% 1.3em;
    background-repeat: no-repeat;
    background-color: #fff;
    font-weight: 300;
    clear: both;
    box-shadow: inset 0 0 2px #e3e3e3;
    max-width: 600px;
    padding: 5rem 2rem 4rem;
    font-family: UniSansBook, sans-serif;
    font-size: 16px;
    margin: 3rem auto 3rem;
    text-align: center;
    position: relative;
  }
  .bosch-login--title {
    font-size: 34px;
    padding: 0 1.5em;
  }
  .bosch-login--login-button {
    line-height: 1.6;
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 1rem;
  }
  .bosch-login--bosch-logo {
    width: 10em;
    top: 1.2em;
    left: 0px;
    position: absolute;
  }
</style>
