<script>
export default {
    name: 'devices-information',
    props: ['devices']
}
</script>

<template>
    <div class="devices-container">
        <div class="table-header">Device Information</div>
        <div class="devices-row devices-header">
            <div class="devices-field os-type">
                OS Type
            </div>
            <div class="devices-field os-version">
                OS Version
            </div>
            <div class="devices-field device-model">
                Device Model
            </div>
            <div class="devices-field app-version">
                COBI.bike app version
            </div>
            <div class="devices-field timestamp">
                Timestamp
            </div>
        </div>

        <div class="devices-row" v-for="(device, index) in devices" :key="index">
            <div class="devices-field os-type">
                {{ device.osType }}
            </div>
            <div class="devices-field os-version">
                {{ device.osVersion }}
            </div>
            <div class="devices-field device-model">
                {{ device.deviceModel }}
            </div>
            <div class="devices-field app-version">
                {{ device.appVersion }}
            </div>
            <div class="devices-field timestamp">
                {{ device.createdAt }}
            </div>
        </div>
        <div class="devices-row no-entries" v-if="!devices || devices.length === 0">
        There is no device information available.
        </div>
    </div>
</template>

<style scoped>
  .table-header {
    padding-left: 15px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .devices-container {
    padding: 0 2em;
    margin-bottom: 2em;
  }

  .devices-header .devices-field {
    padding: 20px 5px;
    background-color: rgb(243, 243, 243);
  }

  .devices-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .devices-row.no-entries {
    justify-content: center;
  }

  .devices-row:nth-of-type(even) {
    background-color: rgb(243, 243, 243);
  }

  .devices-field {
    display: flex;
    flex: 8;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 5px;
  }

  .devices-field:first-of-type {
    padding-left: 15px;
  }
</style>