<template>
  <form aria-live="polite" id="firmware-upload-form" >
    <label for="firmware-file" class="field clearfix">
      <span class="field__label">{{ $t('firmware.file') }}</span>
      <input
        class="field__input"
        type="file"
        id="firmware-file"
        ref="file"
        v-on:change="handleFileUpload()"
      />
      <template v-if="error">
        <span class="field__error">{{ error }}</span>
      </template>
      <template v-else-if="fields.file && !getFileRegex">
        <span class="field__error">{{ $t('firmware.errors.invalid_filename') }}</span>
      </template>
    </label>
    <div class="checkbox-option">
      <input
        class="field__input"
        type="checkbox"
        id="firmware-release"
        v-model="fields.release"
      />
      <label for="firmware-release">{{ $t('firmware.release')}}</label>
    </div>
    <footer class="settings__form__footer clearfix">
      <button
        :disabled="!isFormValid || isLoading"
        id="js-profile-save-button"
        type="submit"
        class="button settings__form__save-button spinning-button button--filled--blue"
        :class="{ loading: isLoading }"
        @click.prevent="buttonClick"
      >
        <span class="spinner"></span>
        <span class="button-label">{{ $t('firmware.upload')}}</span>
      </button>
    </footer>
  </form>
</template>

<script>
  import auth from '../../common/auth';
  import emitter from '../../utils/emitter';

  export default {
    name: 'user-data',
    data() {
      return {
        user: auth.user,
        fields: {
          file: '',
          release: true,
        },
        isLoading: false,
        error: '',
      };
    },
    computed: {
      getFileRegex() {
        if (this.fields.file && this.fields.file.name) {
          return this.fields.file.name.match(/(.+?).zip$/);
        }

        return [];
      },
      isFormValid() {
        return (this.fields.file && this.getFileRegex !== null);
      },
    },
    methods: {
      buttonClick() {
        const formData = new FormData();
        formData.append('file', this.fields.file);

        // Disable submit button
        this.isLoading = true;

        return auth.doAuthenticatedPost(
          'firmware/',
          formData
        )
          .then((res) => {
            if (res) {
              return Promise.resolve(res);
            }
            return Promise.reject(res);
          })
          .then(() => {
            emitter.emit('display-success-message');
            // Enable submit button
            this.isLoading = false;
            // Reset form
            this.fields = {
              file: '',
              release: true,
            };
            // Reset error message
            this.error = '';
            this.$refs.file.value = '';
          })
          .catch((e) => {
            // Enable submit button
            this.isLoading = false;
            // Display generic error message
            emitter.emit('display-error-message');
            // Display 'More recent version available' error
            if (
              e && e.response && e.response.data
            ) {
              const error = e.response.data;
              const i18nId = `firmware.errors.${error.title.toLowerCase()}`;
              if (error.title === 'MORE_RECENT_VERSION_AVAILABLE') {
                // Special case: List more recent available version
                // Exctract version number
                const version = error.detail.split(': ')[1];
                this.error = this.$i18n.t(i18nId, { version });
              } else if (i18nId !== this.$i18n.t(i18nId)) {
                // If translation is available
                this.error = this.$i18n.t(i18nId);
              } else {
                // Otherwise display error message provided by api
                this.error = error.detail;
              }
            }
          });
      },
      handleFileUpload() {
        this.error = '';
        // eslint-disable-next-line prefer-destructuring
        this.fields.file = this.$refs.file.files[0];
      },
    },
  };
</script>

<style>
  #firmware-file {
    border-bottom: none;
  }

  #firmware-upload-form .field__error {
    display: block;
  }

  .settings__form__footer {
    margin-top: 40px;
  }
  .settings__form__footer .settings__form__save-button {
    float: right;
  }
</style>
