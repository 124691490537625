<template>
  <div>
    <slot
      :title="$t('customer-service.page-title')"
      :withError="true"
      :withSuccess="true"
      :controlled="true"
      :error="alerts.error"
      :withTranslation="true"
      :success="alerts.success">
    </slot>

    <div class="content-wrapper">
      <section class="content-section text-center">
        <h1>{{ $t('oem.header') }}</h1>
      </section>

      <div class="flex-centered button-container">
        <button
          v-if="oems.length !== 0 && !loading"
          aria-label="Refresh All OEMs"
          class="button button--filled--blue flex-centered refresh-button"
          @click="refreshAll">
          {{ $t('oem_table.refresh_all') }}

          <img
            :class="refreshAllInProgressClasses"
            src="../common/assets/images/svg/refresh-icon.svg"
          />
        </button>
      </div>

      <div class="oem-table">
        <generic-table
          :rows="oems"
          :loading="loading"
          :columns="columns"
          :noResultsText="$t('oem_table.no_oems_notification')"
          :loadingText="$t('oem_table.fetching_data')"
        >
          <template v-slot:actionsHeader>
            <div class="cell flex-2 actions-field">{{ $t('oem_table.actions') }}</div>
          </template>

          <template
            v-slot:actionsBody="{row}"
            :orgUpdateInProgressClasses="orgUpdateInProgressClasses"
            :refreshApps="refreshApps"
          >
            <div class="cell flex-2 actions-field">
              <div class="row-refresh" @click="refreshApps(row)">
               <img
                  class="refresh-icon"
                  :class='orgUpdateInProgressClasses(row.id)'
                  src="../common/assets/images/svg/refresh-icon.svg"
                />
             </div>
            </div>
          </template>
        </generic-table>
      </div>
    </div>
  </div>
</template>

<script>
  import GenericTable from '../common/components/Table.vue';
  import auth from '../common/auth';
  import emitter from '../utils/emitter';

  export default {
    name: 'oem',
    mounted() {
      this.getData();
    },
    data() {
      return {
        alerts: {
          error: null,
          success: null,
        },
        updateInProgressForOrg: '',
        oems: [],
        loading: false,
        columns: [
          {
            name: this.$i18n.t('oem_table.oem'),
            accessor: 'name',
          },
          {
            name: this.$i18n.t('oem_table.apps_count'),
            accessor: 'appsCount',
          },
        ],
      };
    },
    components: {
      'generic-table': GenericTable,
    },
    methods: {
      orgUpdateInProgressClasses(orgId) {
        return { 'update-in-progress': this.updateInProgressForOrg === orgId };
      },

      async getData(loading = true) {
        this.loading = loading;

        const entries = await auth.doAuthenticatedGet('oem/all/appids');

        this.oems = entries;
        this.loading = false;
      },
      async refreshApps(oem) {
        if (this.updateInProgressForOrg) {
          return;
        }

        this.updateInProgressForOrg = oem.id;

        try {
          await auth.doAuthenticatedPost(
            `oem/${oem.id}`,
            {
              name: oem.name,
            }
          );

          this.getData(false);
          this.updateInProgressForOrg = '';
          emitter.emit('display-success-message');
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
          this.updateInProgressForOrg = '';
          emitter.emit('display-error-message');
        }
      },
      async refreshAll() {
        if (this.updateInProgressForOrg) {
          return;
        }

        this.updateInProgressForOrg = 'all';

        try {
          await auth.doAuthenticatedPost('oem/all/refresh', {});

          this.getData(false);
          this.updateInProgressForOrg = '';
          emitter.emit('display-success-message');
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
          this.updateInProgressForOrg = '';
          emitter.emit('display-error-message');
        }
      },
    },

    computed: {
      refreshAllInProgressClasses() {
        return { 'refresh-all-in-progress': this.updateInProgressForOrg === 'all' };
      },
    },
  };
</script>

<style scoped>
  .button-container {
    margin: 30px 0;
  }

  .refresh-button {
    color: black;
  }

  .refresh-button img {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }

  .oem-table {
    width: 92%;
    margin: 0 auto;
  }

  .refresh-all-in-progress, .update-in-progress {
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
  }

  .row-refresh {
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 100%;
    padding: 5px;
    cursor: pointer;
  }
</style>
