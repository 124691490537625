import { i18n } from '../../main';

const tableColumns = () => [
  {
    name: i18n.global.tc('help_connect.country_name'),
    accessor: 'countryName',
  },
  {
    name: i18n.global.tc('help_connect.country_code'),
    accessor: 'countryCode',
  },
  {
    name: i18n.global.tc('help_connect.terms_and_conditions'),
    accessor: 'languages',
    slotName: 'languagesCountryCell',
    class: 'flex-2',
    info: '<h4>EG: de;en;fr</h4>',
  },
  {
    name: 'Mobile texts', //i18n.global.tc('help_connect.mobile_texts'),
    slotName: 'promotionTexts',
    accessor: 'promotionTexts',
    class: 'flex-2',
  },
  {
    name: i18n.global.tc('help_connect.active'),
    accessor: 'active',
    slotName: 'activeCountryCell',
  },
  {
    name: i18n.global.tc('help_connect.uploadTC'),
    accessor: 'uploadTC',
    slotName: 'uploadTC',
  },
];

export default tableColumns;
