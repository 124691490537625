import BoschLogin from '../pages/BoschLogin.vue';
import NotFound from '../pages/NotFound.vue';
import Forbidden from '../pages/Forbidden.vue';

import auth from '../auth';
import { isLoggedIn, GuardsCheck } from './guards';

auth.setupAuth();

const routes = [
  {
    path: '/',
    redirect: {
      name: 'boschLogin',
    },
  },
  {
    path: '/login',
    name: 'boschLogin',
    component: BoschLogin,
    beforeEnter: GuardsCheck([isLoggedIn]),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
  {
    name: 'Forbidden',
    path: '/forbidden',
    component: Forbidden,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: 'Forbidden',
  },
];

export default routes;
