<template>
  <div>
    <slot
      :title="$t('customer-service.page-title')"
      :withError="true"
      :withSuccess="true"
      :controlled="true"
      :withTranslation="true"
    >
    </slot>

    <div class="content-wrapper">
      <section class="content-section text-center">
        <!-- MASTER SWITCH -->
        <div class="countries-master-switch">
          <span>Master-Switch </span>
          <div class="controls">
              <label id="slowmo">
                <input type="checkbox" v-model="isChecked" @change="handleOpenMasterSwitchModal()">
                <div class="control"></div>
              </label>
            </div>
        </div>
        <h1>COBI active countries</h1>
      </section>

      <div class="help-connect-table">
        <generic-table
          :rows="rows"
          :loading="loading"
          :columns="columns"
          :highlightedRowId="selectedRow ? selectedRow.id : ''"
          :showPagination="true"
          @getPage="getHCCountries"
          :totalEntries="totalEntries"
          :initialPageNumber="page"
          :initialEntriesPerPage="entriesPerPage"
          noResultsText="No Results"
          loadingText="Fetching Data"
        >
          <template v-slot:languagesCountryCell="{ row }">
            <input
              v-if="isSelectedRow(row)"
              class="field__input field__input--row"
              type="text"
              v-model="selectedRow.languages"
              v-on:keydown.enter.prevent
            />
            <div v-if="!isSelectedRow(row)">{{ languagesReadOnly(row) }}</div>
          </template>

          <template v-slot:newCountryCell="{ row, column }">
            <checkbox-cell
              :showCheckbox="isSelectedRow(row)"
              :checked="selectedRow && selectedRow.newExpiresAt"
              :cellValue="row[column.accessor] ? 'Yes' : 'No'"
              fieldName="newExpiresAt"
              @valueChanged="updateSelectedRow"
            >
            </checkbox-cell>
          </template>
          <template v-slot:promotionTexts="{ row, column }">
            <promotion-texts
              :texts="row[column.accessor]"
              v-if="!isSelectedRow(row)"
              @onEditLanguage="(lang) => onEditLanguageTexts(lang, row.id)"
              :countryCode="row.countryCode"
            />
          </template>
          <template v-slot:activeCountryCell="{ row, column }">
            <checkbox-cell
              :showCheckbox="isSelectedRow(row)"
              :checked="selectedRow && selectedRow.active"
              :cellValue="row[column.accessor] ? 'Yes' : 'No'"
              fieldName="active"
              @valueChanged="updateSelectedRow"
            >
            </checkbox-cell>
          </template>

          <template v-slot:uploadTC="{ row }">
            <div v-if="isSelectedRow(row)" style="display:block">
              <div onclick="document.getElementById('fileInputTC').click()">
                <img
                  class="edit-icon"
                  src="../common/assets/images/fallback/icon_plus.png"
                  alt="Upload terms and conditions"
                />
              </div>
              <p id="fileSelectedName">{{ fileSelectedName }}</p>
              <input
                type="file"
                name="fileInputTC"
                id="fileInputTC"
                accept="application/pdf"
                style="display:none"
                @change="changeFileSelectedName"
              />
            </div>
          </template>

          <template v-slot:actionsHeader>
            <div class="cell actions-field">{{ $t('help_connect.edit') }}</div>
          </template>

          <template v-slot:actionsBody="{ row }" :setSelectedRow="setSelectedRow">
            <div class="cell actions-field">
              <button
                :disabled="updateInProgress"
                v-if="showSave(row)"
                class="button save-button button--confirmation button--filled--blue"
                :class="`${row.id}-save`"
                @click="saveRow"
              >
                Save
              </button>
              <div
                v-if="!showSave(row)"
                class="edit-icon"
                :class="[isSelectedRow(row) ? 'edit-icon--selected-row' : '', `${row.id}-edit`]"
                @click="setSelectedRow(row)"
              >
                <img class="edit-icon" src="../common/assets/images/svg/edit-icon.svg" />
              </div>
            </div>
          </template>
        </generic-table>
      </div>
      <edit-promotion-texts-modal
        v-if="editPromotionTextsModalVisible"
        v-on:close="hideEditTextsModal"
        :promotionTexts="promotionTexts"
        :defaultSelectedLanguage="defaultSelectedLanguage"
        :countryCode="editTextsSelectedCountryCode"
      />
      <master-switch-modal
        v-if="openMasterSwitchModal"
        v-on:close="closeMasterSwitchModal"
        :isChecked="isChecked"
        @update:checked="updateChecked"
      />
    </div>
  </div>
</template>

<script>
  import auth from '../common/auth';
  import GenericTable from '../common/components/Table.vue';
  import CheckboxCell from '../components/HCCountries/CheckboxCell.vue';
  import tableColumns from '../components/HCCountries/tableColumns';
  import PromotionTexts from '../components/HCCountries/PromotionTexts.vue';
  import EditPromotionTextsModal from '../components/HCCountries/EditPromotionTextsModal.vue';
  import MasterSwitchModal from '../components/HCCountries/MasterSwitchModal.vue'
  import emitter from '../utils/emitter';
  import config from '../../config/index'

  export default {
    name: 'hc-countries',
    components: {
      'generic-table': GenericTable,
      'checkbox-cell': CheckboxCell,
      'promotion-texts': PromotionTexts,
      'edit-promotion-texts-modal': EditPromotionTextsModal,
      'master-switch-modal': MasterSwitchModal
    },
    async mounted() {
      await this.getHCCountries(this.page, this.entriesPerPage);
    },
    async beforeMount(){
      try {
      const response = await fetch(`${config.API_URL}customerservice/country-removal/status`, {
        headers: {
          Authorization: `Bearer ${this.getBearerTokenFromCookie()}`, // get the access token from cookies
        },
      });
      const data = await response.json();
      this.isChecked = data.isActive; // set isChecked to the value returned by the API
    } catch (error) {
      console.error(error);
    }
    },
    data() {
      return {
        selectedRow: null,
        fileSelectedName: '',
        rows: [],
        columns: tableColumns(),
        entriesPerPage: 15,
        page: 1,
        totalEntries: 0,
        loading: true,
        updateInProgress: false,
        editPromotionTextsModalVisible: false,
        defaultSelectedLanguage: 'en',
        editPromotionTextsRowId: null,
        isChecked: false,
        openMasterSwitchModal: false,
      };
    },
    methods: {
      async getHCCountries(pageNumber = 1, entriesPerPage = 15) {
        const path = `hc-terms-and-conditions?page=${pageNumber}&pageSize=${entriesPerPage}`;
        this.loading = true;

        try {
          const entries = await auth.doAuthenticatedGet(path);
          this.totalEntries = entries.count;

          // Get the generic inactive country and move it to top of countries table
          const genericCountryIndex = entries.rows.findIndex(row => row.countryCode === '07');
          if (genericCountryIndex !== -1) {
            const items = entries.rows.splice(genericCountryIndex, 1);
            entries.rows.splice(0, 0, ...items);
          }

          this.rows = entries.rows?.map((row) => {
    
            const promotionTexts = {};
            Object.keys(row.promoteTexts).forEach((lang) => {
              promotionTexts[lang] = JSON.parse(row.promoteTexts[lang]);
            });

            return {
              ...row,
              newExpiresAt: !!row.newExpiresAt,
              languages: row.languages ? row.languages : '',
              promotionTexts,
            };
          });
          this.loading = false;

          this.page = pageNumber;
          this.entriesPerPage = entriesPerPage;
        } catch (err) {
          this.loading = false;
          emitter.emit('display-error-message');
        }
      },
      changeFileSelectedName() {
        this.fileSelectedName = document.getElementById('fileInputTC').files.item(0).name;
      },
      setSelectedRow(row) {
        this.selectedRow = this.selectedRow && this.selectedRow.id === row.id ? null : { ...row };
        this.fileSelectedName = '';
      },
      hideEditTextsModal() {
        this.editPromotionTextsModalVisible = false;
      },
      closeMasterSwitchModal() {
        this.openMasterSwitchModal = false;
      },
      onEditLanguageTexts(lang, rowId) {
        this.editPromotionTextsModalVisible = true;
        this.defaultSelectedLanguage = lang;
        this.editPromotionTextsRowId = rowId;
      },
      showSave(row) {
        if (!this.selectedRow || row.id !== this.selectedRow.id) {
          return false;
        }

        return (
          row.newExpiresAt !== this.selectedRow.newExpiresAt
          || row.active !== this.selectedRow.active
          || this.selectedRow.languages !== row.languages
          || this.fileSelectedName !== ''
        );
      },
      async saveRow() {
        if (!this.selectedRow) {
          return;
        }
        const tcPattern = /^terms-and-conditions-v[1-9][0-9]*-[a-z][a-z].pdf$/;
        if (this.fileSelectedName !== '' && !this.fileSelectedName.match(tcPattern)) {
          this.updateInProgress = false;
          document.querySelector('html').scrollTop = 0;
          emitter.emit('display-error-message', 'Wrong file format');
          return;
        }

        this.updateInProgress = true;

        try {
          await auth.doAuthenticatedPut(
            `hc-terms-and-conditions/${this.selectedRow.countryCode}`,
            this.selectedRow
          );

          if (this.fileSelectedName !== '') {
            const bodyFormData = new FormData();
            bodyFormData.append(
              'termsAndConditions',
              document.getElementById('fileInputTC').files.item(0)
            );
            await auth.doAuthenticatedPost(
              `hc-terms-and-conditions/${this.selectedRow.countryCode}`,
              bodyFormData
            );
          }

          this.selectedRow = null;
          this.updateInProgress = false;

          this.getHCCountries(this.page, this.entriesPerPage);
          emitter.emit('display-success-message');
        } catch (error) {
          this.updateInProgress = false;
          // The error is displayed at the top of the page
          // Make sure that the user see the error
          document.querySelector('html').scrollTop = 0;
          emitter.emit('display-error-message', error.response.data.detail);
        }
      },
      updateSelectedRow(value, fieldName) {
        this.selectedRow[fieldName] = value;
      },
      isSelectedRow(row) {
        return this.selectedRow && this.selectedRow.id === row.id;
      },
      languagesReadOnly(row) {
        const { languages } = row;

        if (!languages) {
          return '';
        }

        return languages.split(';').reduce((prev, language) => {
          const languageVersion = row.languagesVersions?.[language];

          const separator = prev ? ',' : '';
          const versionToShow = languageVersion ? `.${languageVersion}` : '';

          return `${prev}${separator} ${language}${versionToShow}`;
        }, '');
      },
      handleOpenMasterSwitchModal(){
        this.openMasterSwitchModal= true;
      },
      getBearerTokenFromCookie(){
        const name = `accessToken=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for(let i = 0; i < cookieArray.length; i++) {
          let cookie = cookieArray[i];
          while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
          }
          if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
          }
        }
        return '';
        },
        updateChecked(newValue){
          this.isChecked = newValue;
        }
    },
    computed: {
      editTextsRow() {
        return this.rows.find((row) => row.id === this.editPromotionTextsRowId);
      },
      editTextsSelectedCountryCode() {
        return this.editTextsRow ? this.editTextsRow.countryCode : '';
      },
      promotionTexts() {
        return this.editTextsRow ? this.editTextsRow.promotionTexts : {};
      },
    },
  };
</script>

<style scoped>
.help-connect-table {
  width: 92%;
  margin: 0 auto;
  margin-top: 2rem;
}

.edit-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.field__input--row {
  height: 30px;
  padding: 5px 10px;
  border-radius: 16px;
}

.edit-icon--selected-row {
  filter: invert(1);
}

.save-button {
  padding: 0 15px;
  height: 30px;
}

/* MASTER SWITCH */
.countries-master-switch {
    display: flex;
    align-items: center;
    margin-left: 3rem;
}

.countries-master-switch span {
  font-weight: 600;
  margin-right: 15px;
}
.controls {
  top: 50%;
  left: 50%;
  margin-top: auto;
  white-space: nowrap;
}

.controls label {
  cursor: pointer;
  display: inline;
}

.controls label input {
  display: none;
}

.controls label input:checked+.control {
  background-color: #2d2d37;
  margin-left: 0px;
}

.controls label input:checked+.control:after {
  background-color: #00c8e6;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.3);
  margin-left: 16px;
}

.controls label div {
  display: inline-block;
}

.controls label .control {
  width: 36px;
  height: 16px;
  border-radius: 8px;
  background-color: #bbb;
  margin-left: 5px;
}

.controls label .control:after {
  display: block;
  content: '';
  background-color: #f1f1f1;
  width: 24px;
  height: 24px;
  margin-left: -4px;
  margin-top: -4px;
  border-radius: 12px;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
  -webkit-transition: 225ms;
  transition: 225ms;
}
</style>
