/* eslint-disable */
import auth from '../auth';

/**
 * Middleware that checks if a user is logged in. Otherwise he will be redirected to the login page.
 */
export function isLoggedIn(to, from, next) {
  if (!auth.user.authenticated) {
    auth
      .getCachedCheckAuthPromise()
      .then(() => {
        // make login not accessible after auth
        if (to.name === 'boschLogin') {
          next('loggedin');
        } else {
          next();
        }
      })
      .catch((err) => {
        // always redirect to login if not auth
        if (to.name === 'boschLogin') {
          next();
        } else {
          const redirectTarget = 'boschLogin';
          next({
            name: redirectTarget,
            // Append parameter of pre-login-url to remember redirect
            query: { redirect: to.fullPath },
          });
        }
      });
  } else {
    next();
  }
}

/**
 * Middleware that redirects the customer service user to the right path
 */
export function customerServiceRedirect(to, from, next) {
  const userRoles = auth.user.roles;
  if (userRoles.includes('cobi')) {
    next({
      name: 'account_information',
    });
    return;
  }
  if (userRoles.includes('firmware_manager')) {
    next({
      name: 'firmware',
    });
    return;
  }
  if (userRoles.includes('maintainer')) {
    next({
      name: 'admin',
    });
    return;
  }
  next({
    name: 'Forbidden',
  });
}

/**
 * Middlware that checks if a user has a certain role. Otherwise he will be redirected.
 *
 * @param {string} role Role identifier
 * @param {string?} redirect Component id the user will be redirected to
 * @returns {function(to, from, next)}
 */
export function hasRole(role, redirect) {
  if (!redirect) redirect = 'Forbidden';
  // Return guard function
  return function(to, from, next) {
    redirectIfFalse(auth.user.roles.includes(role), redirect, to, from, next);
  };
}

/**
 * Middlware that checks if a user does *not* have a certain role. Otherwise he will be redirected.
 *
 * @param {string} role Role identifier
 * @param {?string} redirect Component id the user will be redirected to
 * @returns {function(to, from, next)}
 */
export function notHasRole(role, redirect) {
  if (!redirect) redirect = 'Forbidden';
  // Return guard function
  return function(to, from, next) {
    redirectIfFalse(!auth.user.roles.includes(role), redirect, to, from, next);
  };
}

/**
 * Redirects if condition is false
 *
 * @param {boolean} condition
 * @param {string} redirect Component id the user will ber redirected to if the condition is false
 * @param {} to
 * @param {} from
 * @param {} next
 */
export function redirectIfFalse(condition, redirect, to, from, next) {
  if (!redirect) redirect = 'NotFound';
  if (condition) {
    if (to.name === redirect) {
      next({
        name: 'NotFound',
        query: to.query,
      });
    }
    next();
  } else {
    if (to.name === redirect) {
      next();
    } else {
      next({
        name: redirect,
        query: to.query,
      });
    }
  }
}

export function operate(guards, from, to, lastNext, i) {
  const guard = guards[i];
  if (guards.length === i + 1) {
    guard(from, to, lastNext);
  } else {
    guard(from, to, (nextArg) => {
      switch (typeof nextArg) {
        case 'undefined':
          operate(guards, from, to, lastNext, i + 1);
          break;
        case 'object':
          lastNext(nextArg);
          break;
        case 'boolean':
          lastNext(nextArg);
          break;
        case 'string':
          lastNext(nextArg);
          break;
      }
    });
  }
}

/**
 * Chains together a list of middleware guards
 *
 * @param {function(from, to, next)[]} ListOfGuards Array of middleware functions
 */
export function GuardsCheck(ListOfGuards) {
  return function(from, to, next) {
    operate(ListOfGuards, from, to, next, 0);
  };
}
