<template>
  <div class="user-actions">
    <div class="buttons-group">
      <div>
        <button-action
          :disabled="isDeleteUserLoading || deleteConfirmation"
          :isLoading="isDeleteUserLoading"
          :isVisible="isMaintainer"
          id="js-profile-delete-email-button"
          @click='buttonDeleteClick'
        >
          {{$t('account-information.delete-user')}}
        </button-action>

        <button-action
          :disabled="isUnmigrateLoading || unmigrateConfirmation"
          :isLoading="isUnmigrateLoading"
          :isVisible="showMigrationOption"
          id="js-profile-unmigrate-button"
          @click='buttonUnmigrateClick'
        >
          {{$t('account-information.unmigrate-user')}}
        </button-action>

        <button-action
          :disabled="isDeleteEmailLoading || deleteEmailConfirmation"
          :isLoading="isDeleteEmailLoading"
          :isVisible="true"
          id="js-profile-delete-button"
          @click='buttonDeleteEmailClick'
        >
          {{$t('account-information.deletion-email')}}
        </button-action>
      </div>
      <div>
        <button-action
          :disabled="isAddUserRoleLoading || addUserRoleConfirmation"
          :isLoading="isAddUserRoleLoading"
          :isVisible="isMaintainer"
          id="js-profile-add-user-role-button"
          @click='buttonAddUserRoleClick'
        >
          {{$t('account-information.add-user-role')}}
        </button-action>

        <button-action
          :disabled="isRemoveUserRoleLoading || removeUserRoleConfirmation"
          :isLoading="isRemoveUserRoleLoading"
          :isVisible="isMaintainer"
          id="js-profile-remove-user-role-button"
          @click='buttonRemoveUserRoleClick'
        >
          {{$t('account-information.remove-user-role')}}
        </button-action>
      </div>
    </div>
    <div class="confirmations">
      <confirmation-window
        :title="$t('account-information.delete-email-account-text')"
        :isVisible="deleteEmailConfirmation"
        :cancelText="$t('account-information.delete-account-cancel-button')"
        :confirmText="$t('account-information.delete-email-account-confirm-button')"
        confirmId="confirm-del-account-button"
        cancelId="cancel-del-account-button"
        @confirm="confirmDeleteEmailAccount($event)"
        @cancel="cancelConfirmation($event)"
      ></confirmation-window>

      <confirmation-window
        :title="$t('account-information.unmigrate-account-text')"
        :isVisible="unmigrateConfirmation"
        :cancelText="$t('account-information.unmigrate-account-cancel-button')"
        :confirmText="$t('account-information.unmigrate-account-confirm-button')"
        confirmId="confirm-unmigrate-account-button"
        cancelId="cancel-unmigrate-account-button"
        @confirm="confirmUnmigrateAccount($event)"
        @cancel="cancelConfirmation($event)"
      ></confirmation-window>

      <confirmation-window
        :title="$t('account-information.delete-account-text')"
        :isVisible="deleteConfirmation"
        :cancelText="$t('account-information.delete-account-cancel-button')"
        :confirmText="$t('account-information.delete-account-confirm-button')"
        confirmId="confirm-delete-account-button"
        cancelId="cancel-delete-account-button"
        @confirm="confirmDeleteAccount($event)"
        @cancel="cancelConfirmation($event)"
      >
        <template v-slot:customContent>
          <div>
            <div
              class="checkbox-option"
              v-if="isBoschAccount"
            >
              <input
                type="checkbox"
                name="delete-obc-account"
                id="delete-obc-account"
                v-model="deleteOBCAccount"
              />
              <label
                for="delete-obc-account"
                v-html="$t('account-information.delete-account-obc-text')"
              ></label>
            </div>
          </div>
        </template>
      </confirmation-window>

      <confirmation-window
        :title="$t('account-information.user-role-confirmation-text')"
        :isVisible="addUserRoleConfirmation"
        :cancelText="$t('account-information.add-user-role-cancel-button')"
        :confirmText="$t('account-information.add-user-role-confirm-button')"
        :confirmDisabled="!selectedUserRole"
        confirmId="confirm-add-user-role-button"
        cancelId="cancel-add-user-role-button"
        @confirm="confirmAddUserRole($event)"
        @cancel="cancelConfirmation($event)"
      >
        <template v-slot:customContent>
          <div class="account-confirmation-prompt-dropdowm-container">
            <cobi-dropdown
              :options="availableUserRoles"
              :selectedValue="selectedUserRole"
              :onValueChange="handleDropdownSelection"
              :defaultLabelText="$t('account-information.user-role-dropdown-default')"
            ></cobi-dropdown>
          </div>
        </template>
      </confirmation-window>

      <confirmation-window
        :title="$t('account-information.user-role-confirmation-text')"
        :isVisible="removeUserRoleConfirmation"
        :cancelText="$t('account-information.remove-user-role-cancel-button')"
        :confirmText="$t('account-information.remove-user-role-confirm-button')"
        confirmId="confirm-remove-user-role-button"
        cancelId="cancel-remove-user-role-button"
        :confirmDisabled="!selectedUserRole"
        @confirm="confirmRemoveUserRole($event)"
        @cancel="cancelConfirmation($event)"
      >
        <template v-slot:customContent>
          <div class="account-confirmation-prompt-dropdowm-container">
            <cobi-dropdown
              :options="availableUserRoles"
              :selectedValue="selectedUserRole"
              :onValueChange="handleDropdownSelection"
              :defaultLabelText="$t('account-information.user-role-dropdown-default')"
            ></cobi-dropdown>
          </div>
        </template>
      </confirmation-window>
    </div>
  </div>
</template>

<script>
  import auth from '../../common/auth';
  import Dropdown from '../../common/components/Dropdown.vue';
  import ConfirmationWindow from './ConfirmationWindow.vue';
  import ButtonAction from './ButtonAction.vue';
  import emitter from '../../utils/emitter';

  const filterType = {
    ADD: 0,
    REMOVE: 1,
  };

  const logger = console;

  export default {
    props: {
      user: {
        type: Object,
        default: {},
      },
      customer: {
        type: Object,
        default: {},
      },
      isMaintainer: Boolean,
      isBoschAccount: Boolean,
    },
    emits: ['resetCustomerData'],
    name: 'user-actions',
    components: {
      'cobi-dropdown': Dropdown,
      'confirmation-window': ConfirmationWindow,
      'button-action': ButtonAction,
    },
    data() {
      return {
        isDeleteEmailLoading: false,
        isDeleteUserLoading: false,
        isUnmigrateLoading: false,
        isAddUserRoleLoading: false,
        isRemoveUserRoleLoading: false,
        deleteEmailConfirmation: false,
        unmigrateConfirmation: false,
        deleteConfirmation: false,
        addUserRoleConfirmation: false,
        removeUserRoleConfirmation: false,
        deleteOBCAccount: false,
        availableUserRoles: [],
        selectedUserRole: null,
      };
    },
    computed: {
      showMigrationOption() {
        return this.user.migration_state === 'migrated' && this.isMaintainer;
      },
    },
    methods: {
      resetLocalState() {
        this.deleteEmailConfirmation = false;
        this.deleteConfirmation = false;
        this.unmigrateConfirmation = false;
        this.addUserRoleConfirmation = false;
        this.removeUserRoleConfirmation = false;
        this.availableUserRoles = [];
        this.selectedUserRole = null;
        this.deleteOBCAccount = false;
      },
      buttonDeleteEmailClick() {
        this.resetLocalState();
        this.deleteEmailConfirmation = true;
      },

      buttonDeleteClick() {
        this.resetLocalState();
        this.deleteConfirmation = true;
      },

      buttonUnmigrateClick() {
        this.resetLocalState();
        this.unmigrateConfirmation = true;
      },

      async buttonAddUserRoleClick() {
        try {
          this.resetLocalState();
          this.addUserRoleConfirmation = true;
          const availableRolesRequest = await auth.doAuthenticatedGet(
            `customerservice/user/${this.customer.user.account}/role?filter=${filterType.ADD}`
          );

          if (!availableRolesRequest) throw new Error('NO RESPONSE');

          this.availableUserRoles = availableRolesRequest.availableRoles;
        } catch (error) {
          // Display error message
          emitter.emit('display-error-message');
        }
      },
      async buttonRemoveUserRoleClick() {
        try {
          this.resetLocalState();
          this.removeUserRoleConfirmation = true;
          const availableRolesRequest = await auth.doAuthenticatedGet(
            `customerservice/user/${this.customer.user.account}/role?filter=${filterType.REMOVE}`
          );

          if (!availableRolesRequest) throw new Error('NO RESPONSE');

          this.availableUserRoles = availableRolesRequest.availableRoles;
        } catch (error) {
          // Display error message
          emitter.emit('display-error-message');
        }
      },

      cancelConfirmation() {
        this.deleteConfirmation = false;
        this.deleteEmailConfirmation = false;
        this.unmigrateConfirmation = false;
        this.addUserRoleConfirmation = false;
        this.removeUserRoleConfirmation = false;
        this.availableUserRoles = [];
        this.selectedUserRole = null;
      },

      async confirmDeleteEmailAccount() {
        this.isDeleteEmailLoading = true;
        try {
          const deleteRequest = await auth.doAuthenticatedDelete(
            `customerservice/user/${this.customer.user.account}/delete`
          );
          if (!deleteRequest) throw new Error('NO RESPONSE');

          emitter.emit('display-success-message', this.$i18n.t('account-information.success'));

          // Enable submit button and clean data
          this.$emit('resetCustomerData');

          this.isDeleteEmailLoading = false;
          this.deleteEmailConfirmation = false;
        } catch (error) {
          logger.log(error);
          this.isDeleteEmailLoading = false;
          this.deleteEmailConfirmation = false;
          // Display error message
          emitter.emit('display-error-message');
        }
      },

      async confirmUnmigrateAccount() {
        this.isUnmigrateLoading = true;

        try {
          const unmigrateRequest = await auth.doAuthenticatedGet(
            `customerservice/user/${this.customer.user.account}/unmigrate`
          );
          if (!unmigrateRequest) throw new Error('NO RESPONSE');

          emitter.emit('display-success-message', this.$i18n.t('account-information.unmigrated-success'));

          // Enable submit button and clean data
          this.$emit('resetCustomerData');

          this.isUnmigrateLoading = false;
          this.unmigrateConfirmation = false;
        } catch (error) {
          logger.log(error);
          this.isUnmigrateLoading = false;
          this.unmigrateConfirmation = false;
          // Display error message
          emitter.emit('display-error-message');
        }
      },

      async confirmDeleteAccount() {
        this.isDeleteUserLoading = true;
        try {
          const unmigrateRequest = await auth.doAuthenticatedDelete(
            `customerservice/user/${this.customer.user.account}/cleanup/${this.deleteOBCAccount}`
          );
          if (!unmigrateRequest) throw new Error('NO RESPONSE');

          emitter.emit('display-success-message', this.$i18n.t('account-information.delete-account-success'));

          // Enable submit button and clean data
          this.$emit('resetCustomerData');

          this.isDeleteUserLoading = false;
          this.deleteConfirmation = false;
        } catch (error) {
          logger.log(error);
          this.isDeleteUserLoading = false;
          this.deleteConfirmation = false;
          // Display error message
          emitter.emit('display-error-message');
        }
      },

      async confirmAddUserRole() {
        try {
          this.isAddUserRoleLoading = true;

          const addUserRoleRequest = await auth.doAuthenticatedPut(`customerservice/user/${this.customer.user.account}/role/${this.selectedUserRole}`, {});

          if (!addUserRoleRequest) throw new Error('NO RESPONSE');

          emitter.emit('display-success-message', this.$i18n.t('account-information.add-user-role-success'));
        } catch (error) {
          // Display error message
          emitter.emit('display-error-message');
        }

        this.isAddUserRoleLoading = false;
        this.addUserRoleConfirmation = false;
        this.availableUserRoles = [];
        this.selectedUserRole = null;
      },

      async confirmRemoveUserRole() {
        try {
          this.isRemoveUserRoleLoading = true;

          const removeUserRoleRequest = await auth.doAuthenticatedDelete(
            `customerservice/user/${this.customer.user.account}/role/${this.selectedUserRole}`
          );

          if (!removeUserRoleRequest) throw new Error('NO RESPONSE');

          emitter.emit('display-success-message', this.$i18n.t('account-information.remove-user-role-success'));
        } catch (error) {
          // Display error message
          emitter.emit('display-error-message');
        }

        this.isRemoveUserRoleLoading = false;
        this.removeUserRoleConfirmation = false;
        this.availableUserRoles = [];
        this.selectedUserRole = null;
      },

      handleDropdownSelection(selectedValue) {
        this.selectedUserRole = selectedValue;
      },
    },
  };
</script>

<style scoped>
  .account-confirmation-prompt-dropdowm-container {
    width: 250px;
  }

  .checkbox-option {
    text-align: center;
  }

  .checkbox-option label {
    font-size: 12px;
  }

  .user-actions {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .user-actions button {
    width: auto;
    margin-bottom: 10px;
  }

  .buttons-group {
    padding: 0 2em 1.5em 0;
  }

  .confirmations {
    width: 100%;
  }
</style>
