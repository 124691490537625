<template>
  <article>
    <header class="tabs">
      <ul>
        <li v-for="(tab, index) in items" :key="index" @click="selectTab(tab)">
          <slot name="tab-nav-item" :tabName="tab.name" :isActive="tab.isActive"></slot>
        </li>
      </ul>
    </header>
    <section class="tabs-details">
      <slot></slot>
    </section>
  </article>
</template>

<script>
  import emitter from '../../utils/emitter';

  export default {
    props: [
      'items',
    ],
    methods: {
      selectTab(selectedTab) {
        emitter.emit('select-hc-countries-tab', selectedTab.name);
      },
    },
  };
</script>

<style scoped>
.tabs {
  margin: 0 10px;
}

.tabs-details {
  padding: 10px;
}

ul {
  display: flex;
  padding: 0;
  list-style: none;
}

</style>
