<template>
  <transition name="filters-fade">
    <div class="filters-container" v-if="showFilters">
      <div class="filters-list">
        <div class="form-group small-input-field">
          <label>{{ $t("log_table.table_log_id") }}</label>
          <search-input
            name="logId"
            :showSearchIcon="true"
            :placeholder="$t('log_table.search')"
            v-model='filters.logId'>
          </search-input>
        </div>

        <div class="form-group large-input-field">
          <label>{{ $t('log_table.table_identifier_label') }}</label>
          <search-input
            name="identifier"
            :showSearchIcon="true"
            :placeholder="$t('log_table.search')"
            v-model='filters.identifier'>
          </search-input>
        </div>

        <div class="form-group large-input-field">
          <label>{{ $t("log_table.table_file_name") }}</label>
          <search-input
            name="fileName"
            :showSearchIcon="true"
            :placeholder="$t('log_table.search')"
            v-model='filters.fileName'>
          </search-input>
        </div>

        <div class="form-group small-input-field">
          <label>{{ $t("log_table.table_platform") }}</label>
          <cobi-dropdown
            :options="platforms || []"
            :selectedValue="filters.platform || ''"
            :onValueChange="onChangeOs"
            :variant="variant"
            :colorScheme="colorScheme"
            :defaultLabelText='$t("log_table.all")'>
          </cobi-dropdown>
        </div>

        <div class="form-group small-input-field">
          <label>{{ $t("log_table.table_log_type") }}</label>
          <cobi-dropdown
            :options="logTypes || []"
            :selectedValue="filters.logType || ''"
            :onValueChange="onChangeLogType"
            variant="rounded"
            colorScheme="light"
            :defaultLabelText='$t("log_table.all")'>
          </cobi-dropdown>
        </div>

        <div class="form-group creation-date-fields">
          <label>{{ $t("log_table.table_creation_date") }}</label>

          <div class="calendar-inputs-container">
            <p class="calendar-label">{{ $t("log_table.from") }}</p>
            <input type="date" name="startDate" v-model="filters.startDate"/>

            <p class="calendar-label">{{ $t("log_table.to") }}</p>
            <input type="date" name="endDate" v-model="filters.endDate"/>
          </div>
        </div>

        <div class="file-size-field">
          <label>{{ $t("log_table.table_file_size") }}</label>

          <div class="calendar-inputs-container">
            <p class="calendar-label">{{ $t("log_table.min") }}</p>

            <search-input
              name="minFileSize"
              type="number"
              v-model='filters.minFileSize'
              :showSearchIcon="false">
            </search-input>

            <p class="calendar-label">{{ $t("log_table.max") }}</p>

            <search-input
              name="maxFileSize"
              type="number"
              v-model='filters.maxFileSize'
              :showSearchIcon="false">
            </search-input>
          </div>
        </div>
      </div>

      <div class="action-buttons">
        <button
          class="button button--confirmation button--white clear-filters"
          @click="$emit('clearFilters')"
        >
          {{ $t("log_table.clear_filters") }}
        </button>

        <button
          class="button button--confirmation button--coral apply-filters"
          @click="$emit('applyFilters')">
            {{ $t("log_table.apply_filters") }}
          </button>
      </div>
    </div>
  </transition>
</template>

<script>
  import SearchInput from './SearchInput.vue';
  import Dropdown from '../../common/components/Dropdown.vue';
  import { VARIANT_ROUNDED, COLOR_SCHEME_LIGHT } from '../../common/constants/dropdown';

  export default {
    name: 'table-filters',
    props: ['showFilters', 'filters', 'logTypes', 'platforms'],
    emits: ['changeOs', 'changeLogType'],
    components: {
      'search-input': SearchInput,
      'cobi-dropdown': Dropdown,
    },
    data() {
      return {
        variant: VARIANT_ROUNDED,
        colorScheme: COLOR_SCHEME_LIGHT,
      };
    },
    methods: {
      onChangeOs(value) {
        this.$emit('changeOs', value);
      },
      onChangeLogType(value) {
        this.$emit('changeLogType', value);
      },
    },
  };
</script>

<style scoped>
  /** Generic components styles */
  p {
    margin: 0;
  }

  input {
    width: 100%;
    border-radius: 20px;
    border: none;
    padding: 8px 15px;
    font-family: 'UniSansBook'
  }

  input::placeholder {
    font-weight: 300;
    color: #21212152;
    font-size: 14px;
  }

  label {
    color: #fff;
    margin-bottom: 0;
    padding-top: 0;
    font-size: 14px;
  }

  /** Transition animation for filters container */
  .filters-fade-enter-active, .filters-fade-leave-active  {
    max-height: 310px;
    overflow-y: hidden;
    -webkit-transition: max-height 0.3s linear;
    -moz-transition: max-height 0.3s linear;
    -o-transition: max-height 0.3s linear;
    transition: max-height 0.3s linear;
  }

  .filters-fade-enter, .filters-fade-leave-to {
    max-height: 0px ;
  }

  /** Filters container and list */
  .filters-container {
    background-color: #2d2d37;
    width: 100%;
    margin-top: 10px;
    padding: 15px;
  }

  .filters-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 15px;
  }

  /** Reusable classes for input groups and sizes */
  .form-group {
    margin-right: 40px;
  }

  .small-input-field {
    width: 170px;
    height: 100px;
  }

  .large-input-field {
    width: 250px;
  }

  /** Calendar input container */
  .calendar-inputs-container {
    display: flex;
    align-items: center;
  }

  .calendar-inputs-container input {
    width: 140px;
    padding: 5px 10px;
  }

  .calendar-inputs-container p:nth-child(3) {
    margin-left: 30px;
  }

  .calendar-label {
    opacity: 0.6;
    color: #fff;
    margin-right: 10px;
  }

  .creation-date-fields {
    margin-right: 120px;
  }

  /** FILE SIZE FIELD */
  .file-size-field {
    width: 250px;
  }

  /** ACTION BUTTONS */
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .action-buttons button {
    border-radius: 20px;
    border: none;
  }

  .action-buttons button:first-child {
    margin-right: 30px;
  }

</style>
