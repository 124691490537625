<template>
  <div>
    <form aria-live="polite" class="settings__form">
      <label class="field clearfix">
        <span class="field__label">{{ $t('hubstatus.serial_number') }}</span>
        <input
          class="field__input"
          type="text" id="serial-number"
          v-model.trim="fields.serial_number"
          v-on:keydown.enter.prevent
        />
          <span class="field__error"></span>
      </label>
      <footer class="settings__form__footer clearfix">
        <button
          :disabled="!isFormValid || isLoading"
          id="js-profile-save-button"
          type="submit"
          class="button settings__form__save-button spinning-button button--filled--blue"
          :class="{ loading: isLoading }"
          @click.prevent="buttonClick"
        >
          <span class="spinner"></span>
          <span class="button-label">{{ $t('hubstatus.request')}}</span>
        </button>
      </footer>
    </form>
    <form
      v-if="response"
      aria-live="polite"
      class="settings__form"
      id="hub__response__form"
    >
      <h3 class="lead-settings">
        {{ $t('hubstatus.responses.' + response) }}
      </h3>
      <template v-if="owner_mail">
        <p class="lead-settings">
          {{ $t('hubstatus.verify_mail') }}
          <br>
          <b>{{ owner_mail }}</b>
        </p>
        <footer class="settings__form__footer clearfix">
          <button
            :disabled="isReleaseLoading"
            id="js-profile-release-button"
            type="submit"
            class="button settings__form__save-button spinning-button button--filled--blue"
            :class="{ loading: isReleaseLoading }"
            @click.prevent="buttonReleaseClick"
          >
            <span class="spinner"></span>
            <span class="button-label">{{ $t('hubstatus.release')}}</span>
          </button>
          <button
            :disabled="isDeleteLoading"
            id="js-profile-delete-button"
            type="submit"
            class="button settings__form__save-button spinning-button button--filled--blue"
            :class="{ loading: isDeleteLoading }"
            @click.prevent="buttonDeleteClick"
          >
            <span class="spinner"></span>
            <span class="button-label">{{ $t('hubstatus.delete')}}</span>
          </button>
        </footer>
      </template>
    </form>
  </div>
</template>

<script>
  import auth from '../../common/auth';
  import emitter from '../../utils/emitter';

  export default {
    name: 'user-data',
    data() {
      return {
        user: auth.user,
        fields: {
          serial_number: '',
        },
        response: '',
        owner_mail: '',
        isLoading: false,
        isReleaseLoading: false,
        isDeleteLoading: false,
      };
    },
    computed: {
      isFormValid() {
        return (this.fields.serial_number);
      },
    },
    methods: {
      buttonClick() {
        // Disable submit button
        this.isLoading = true;

        return auth.doAuthenticatedGet(
          `customerservice/${this.fields.serial_number}`
        )
          .then((hubResponse) => {
            // Reset state
            this.response = '';
            this.owner_mail = '';
            // Enable form again
            this.isLoading = false;

            if (hubResponse) {
              // Response is valid, retrieve message
              this.response = hubResponse.result;
              if (hubResponse.result === 'SYSTEM_IS_ACTIVATED') {
                // Hub is activated, find owner
                return auth.doAuthenticatedGet(
                  `customerservice/${this.fields.serial_number}/owner`
                )
                  .then((hubOwnerResponse) => {
                    if (hubOwnerResponse && hubOwnerResponse.result === 'SYSTEM_OWNER_WAS_FOUND') {
                      this.owner_mail = hubOwnerResponse.data;
                      return Promise.resolve(hubOwnerResponse);
                    }
                    // Response is invalid
                    return Promise.reject(hubOwnerResponse);
                  });
              }
              return Promise.resolve();
            }
            return Promise.reject();
          })
          .catch(() => {
            // Display generic error message
            emitter.emit('display-error-message');
          });
      },
      buttonReleaseClick() {
        // Disable submit button
        this.isReleaseLoading = true;

        return auth.doAuthenticatedGet(
          `customerservice/${this.fields.serial_number}/release`
        )
          .then((res) => {
            if (res && res.result === 'SYSTEM_OWNER_WAS_INFORMED') {
              return Promise.resolve(res);
            }
            return Promise.reject(res);
          })
          .then(() => {
            emitter.emit('display-success-message');
            // Enable submit button
            this.isReleaseLoading = false;
          })
          .catch(() => {
            // Enable submit button
            this.isReleaseLoading = false;
            // Display generic error message
            emitter.emit('display-error-message');
          });
      },
      buttonDeleteClick() {
        // Disable submit button
        this.isDeleteLoading = true;

        return auth.doAuthenticatedGet(
          `customerservice/${this.fields.serial_number}/delete`
        )
          .then((res) => {
            if (res) {
              return Promise.resolve(res);
            }
            return Promise.reject(res);
          })
          .then(() => {
            emitter.emit('display-success-message');
            // Enable submit button
            this.isDeleteLoading = false;
            // Reload hub status
            this.buttonClick();
          })
          .catch(() => {
            // Enable submit button
            this.isDeleteLoading = false;
            // Display generic error message
            emitter.emit('display-error-message');
            // Reload hub status
            this.buttonClick();
          });
      },
    },
  };
</script>

<style>
  .settings__form__footer {
    margin-top: 40px;
  }
  .settings__form__footer .settings__form__save-button {
    float: right;
  }

  .lead-settings {
    text-align: left;
  }

  #hub__response__form {
    padding: 2em;
    background-color: white;
    margin-top: 3em;
  }

  #hub__response__form .button {
    margin-left: .5em;
    width: initial;
  }

  #hub__response__form .button.loading .button-label {
    opacity: .8;
  }

  @media all and (max-width: 480px) {
    .hub__date-added {
      clear: both;
    }
    .settings__form {
      margin-top: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .form__title {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
  @media all and (max-width: 640px) {
    .landing__content {
      margin-top: 40px;
      width: 95%;
      padding-left: 5px;
    }
  }
</style>
