<template>
  <div class="input-container" :class="{'search-input-container': showSearchIcon}">
    <input
      :type="type || 'text'"
      :value="modelValue"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <img
      v-if="showSearchIcon"
      class="button-icon"
      src="../../common/assets/images/svg/search-icon.svg"
    />
  </div>
</template>

<script>
  export default {
    name: 'search-input',
    props: ['modelValue', 'showSearchIcon', 'type'],
  };
</script>

<style scoped>
  input {
    width: 100%;
    border-radius: 20px;
    border: none;
    padding: 8px 15px;
    font-family: 'UniSansBook'
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    font-weight: 300;
    color: #21212152;
    font-size: 14px;
  }

  .input-container {
    position: relative;
  }

  .input-container.search-input-container input {
    padding-right: 35px;
  }

  .input-container img {
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    top: -4px;
    opacity: 0.6;
  }
</style>
