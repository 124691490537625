// Authentication controller
import { reactive } from 'vue';
import config from '../../../config';
import axiosApiInstance, { refreshAccessToken } from '../utils/axios';
import { removeAuthTokens } from './accessToken';

export const { API_URL } = config;
const { WEB_URL, OBC_KC_LOGOUT_URI, OBC_KC_REDIRECT_URI } = config;
const LOGOUT_URL = `${API_URL}account/logout`;
const CHECK_URL = `${API_URL}account/`;

export default reactive({
  // User object will let us check authentication status
  user: {
    id: '',
    email: '',
    name: '',
    lastname: '',
    authenticated: false,
    isBosch: undefined,
    language: '',
    country: '',
  },
  /**
   * Sends a request to the logout endpoint and deletes persistant user data
   *
   * @returns {Promise}
   */
  logout() {
    return axiosApiInstance({
      method: 'get',
      url: LOGOUT_URL,
      withCredentials: true,
    })
      .then(() => {
        this.user.authenticated = false;
        // Get idToken for Logout and do redirect
        (async () => {
          const { idToken } = (await refreshAccessToken());
          removeAuthTokens();
          window.location.href = `${OBC_KC_LOGOUT_URI}?post_logout_redirect_uri=${encodeURI(OBC_KC_REDIRECT_URI)}&id_token_hint=${idToken}`;
        })();
      })
      .catch((e) => {
        console.log(e);
        return Promise.reject(e);
      });
  },

  setupAuth() {
    // Load profile informaiton
    this.getCachedCheckAuthPromise();
  },

  /**
   * Returns cached version of checkAuth promise to not send multiple requests at the same time
   *
   * @returns {Promise}
   */
  getCachedCheckAuthPromise() {
    if (this._checkAuthPromise) {
      return this._checkAuthPromise;
    }
    this._checkAuthPromise = this.checkAuth();
    return this._checkAuthPromise;
  },

  /**
   * Returns cached version of checkAuth promise to not send multiple requests at the same time
   */
  clearCachedCheckAuthPromise() {
    // "Returns cached version of checkAuth promise to not send multiple requests at the same time"
    // Well, no?
    this._checkAuthPromise = null;
  },

  /**
   * Checks if user is logged in and persists account data
   *
   * @returns {Promise}
   */
  checkAuth() {
    return axiosApiInstance({
      method: 'get',
      url: CHECK_URL,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 304) {
          return res.data;
        }
        this.user.authenticated = false;
        return Promise.reject(new Error('checkAuth() - error: Not logged in'));
      })
      .then((data) => {
        // Store data in user variable
        this.user.name = data.firstname;
        this.user.lastname = data.lastname ? data.lastname : '';
        this.user.email = data.email;
        this.user.authenticated = true;
        this.user.roles = data.roles;
        this.user.isBosch = data.isBosch;
        this.user.language = data.language;
        this.user.country = data.country;
        // Update lastSeen if the difference between today and stored value of lastSeen is greater than one day
        const storedLastSeen = new Date(data.lastSeen);
        const currentDate = new Date();

        if (
          !(
            storedLastSeen.getDay() === currentDate.getDay()
            && storedLastSeen.getMonth() === currentDate.getMonth()
            && storedLastSeen.getFullYear() === currentDate.getFullYear()
          )
        ) {
          this.doAuthenticatedPut('account/lastseen', {});
        }

        return Promise.resolve(this.user);
      })
      .catch((err) => {
        this.user.authenticated = false;
        if (err.response) return Promise.reject(err.response);

        return Promise.reject(new Error('checkAuth() - error: Not logged in'));
      });
  },

  /**
   * Sends a GET request to an endpoint and returns json object
   *
   * @param {string} ENDPOINT Endpoint url
   * @returns {Promise} Promise returning json object
   */
  async doAuthenticatedGet(ENDPOINT) {
    try {
      const res = await axiosApiInstance({
        method: 'get',
        url: API_URL + ENDPOINT,
      });

      return res.data;
    } catch (err) {
      console.log('doAuthenticatedGet() - error');
      console.log(err);
      return Promise.reject(err);
    }
  },

  /**
   * Sends a GET request to an endpoint and returns json object and response headers
   *
   * @param {string} ENDPOINT Endpoint url
   * @returns {Promise} Promise returning json object and response headers
   */
  async doAuthenticatedGetWithHeaders(ENDPOINT) {
    try {
      const axiosResult = await axiosApiInstance({
        method: 'get',
        url: API_URL + ENDPOINT,
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
      });

      return { data: axiosResult.data, headers: axiosResult.headers };
    } catch (err) {
      console.log('doAuthenticatedGet() - error');
      console.log(err);
      return err;
    }
  },

  /**
   * Sends a POST request to an endpoint and returns json object
   *
   * @param {string} ENDPOINT Endpoint url
   * @param {object} data Object containing post parameter
   * @returns {Promise} Promise returning json object
   */
  async doAuthenticatedPost(ENDPOINT, data) {
    try {
      const res = await axiosApiInstance({
        method: 'post',
        url: API_URL + ENDPOINT,
        data,
      });
      return res.data;
    } catch (err) {
      console.log('doAuthenticatedPost() - error');
      console.log(err);
      return Promise.reject(err);
    }
  },

  /**
   * Sends a PUT request to an endpoint and returns json object
   *
   * @param {string} ENDPOINT Endpoint url
   * @param {object} data Object containing post parameter
   * @returns {Promise} Promise returning json object
   */
  async doAuthenticatedPut(ENDPOINT, data) {
    try {
      const res = await axiosApiInstance({
        method: 'put',
        url: API_URL + ENDPOINT,
        data,
      });
      return res.data;
    } catch (err) {
      console.log('doAuthenticatedPut() - error');
      console.log(err);
      return Promise.reject(err);
    }
  },

  /**
   * Sends a DELETE request to an endpoint and returns json object
   *
   * @param {string} ENDPOINT Endpoint url
   * @returns {Promise} Promise returning json object
   */
  async doAuthenticatedDelete(ENDPOINT) {
    try {
      const res = await axiosApiInstance({
        method: 'delete',
        url: `${API_URL}${ENDPOINT}`,
      });
      return res.data;
    } catch (err) {
      console.log('doAuthenticatedDelete() - error');
      console.log(err);
      return Promise.reject(err);
    }
  },
  getWebURL() {
    return WEB_URL;
  },
});
