<template>
  <div class="header-container">
    <nav>
      <div class="main-header" ref="mainHeader">
        <a href="https://www.bosch-ebike.com/de/produkte/cobi-bike-app" class="logo" ref="logo" role="logo" aria-label="Image of a Logo that redirects to the Bosch COBI page">
          <img src="../assets/images/svg/logo.svg" alt="COBI.bike"
            onerror="this.src='../assets/images/fallback/logo.png'; this.onerror = null " />
        </a>
        <div class="menu-button-container" :class="{ mobile: isMobileMode }" v-if="user.authenticated && isMobileMode"
          v-click-outside="hideMenuItems">
          <button class="menu-button" @click="toggleMobileMenu">
            <img src="../assets/images/svg/hamburger-icon.svg" :aria-label="$t('header.menu')" class="hamburger-icon" :alt="$t('alt_text.menu')" />
            {{ $t('header.menu') }}
          </button>
        </div>
        <transition name="slide">
          <div class="clearfix" :class="navMenuClass" v-if="isMenuVisible" id="menu">
            <ul class="nav-menu__list clearfix">
              <li v-for="item in availableMenuItems" :key="item.link">
                <router-link active-class="current" :to="item.link">
                  {{ $t(item.translation) }}
                </router-link>
              </li>
            </ul>
          </div>
        </transition>
        <div class="user-container" ref="userContainer">
          <div class="user-email" v-if="isUserEmailAvailable">
            <router-link to="/account" class="user-account">
              <p>
                <img role="profile-photo" src="../assets/images/svg/profile-photo.svg"
                  :alt="$t('alt_text.profile_photo')" class="user-image" />
                {{ user.email }}
              </p>
            </router-link>
          </div>
          <div v-else-if="user.authenticated" class="user-email disabled">
            <img src="../assets/images/svg/profile-photo.svg" :alt="$t('alt_text.profile_photo')" class="user-image" />
            <p>{{ user.email }}</p>
          </div>
          <div @click="logout()" v-if="user.authenticated" class="button button--white login-logout-button">
            Logout
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
  import auth from '../auth';
  import config from '../../../config';

  const CHARACTER_WIDTH = 9;
  const DEFAULT_PADDING_WIDTH = 51;

  export default {
    name: 'cobi-header',
    props: ['navigation', 'linkToAccount'],
    data() {
      return {
        user: auth.user,
        logout: auth.logout,
        locale: null,
        isMobileMode: false,
        menuOpen: false,
        userContainerWidth: 0,
      };
    },
    computed: {
      navMenuClass() {
        return this.isMobileMode ? 'nav-menu-mobile' : 'nav-menu';
      },
      isMenuVisible() {
        return (this.isMobileMode && this.menuOpen) || !this.isMobileMode;
      },
      availableMenuItems() {
        return this.navigation.filter(
          (item) => (this.user.authenticated || !item.requireAuthentication)
            && ((this.user.roles && this.user.roles.includes(item.role)) || !item.role)
        );
      },
      isUserEmailAvailable() {
        return this.linkToAccount && this.user.authenticated;
      },
    },
    methods: {
      setLocale() {
        // we get the users selected language if this is not available we use from query param
        const locale = this.getUserLocale() || this.$route.query.locale;
        const browserLanguage = this.getBrowserLanguage();

        if (locale && config.LANGUAGES.includes(locale)) {
          this.$i18n.locale = locale;
        } else if (browserLanguage && config.LANGUAGES.includes(browserLanguage)) {
          this.$i18n.locale = browserLanguage;
        }
      },
      getUserLocale() {
        const { user } = this;
        return user && user.language;
      },
      getBrowserLanguage() {
        return navigator.language && navigator.language.slice(0, 2);
      },
      toggleMobileMenu() {
        this.menuOpen = !this.menuOpen;
      },
      hideMenuItems() {
        this.menuOpen = false;
      },
      validateViewType() {
        const mainHeaderElement = this.$refs.mainHeader;
        const logoElement = this.$refs.logo;
        const userContainerElement = this.$refs.userContainer;

        const totalWidth = mainHeaderElement.offsetWidth;
        const usedWidth = logoElement.offsetWidth + userContainerElement.offsetWidth;
        const menuBarWidth = totalWidth - usedWidth;

        const neededWidth = this.availableMenuItems.reduce((total, curr) => {
          const currentLength = this.$i18n.t(curr.translation).length;
          // eslint-disable-next-line no-param-reassign
          total += currentLength * CHARACTER_WIDTH + DEFAULT_PADDING_WIDTH;
          return total;
        }, 0);

        if (neededWidth <= menuBarWidth) {
          this.isMobileMode = false;
          return;
        }

        this.isMobileMode = true;
      },
    },
    mounted() {
      this.setLocale();

      window.addEventListener('resize', this.validateViewType);
      window.addEventListener('beforeunload', this.destroyComponent);

      this.validateViewType();
    },
    updated() {
      this.setLocale();
      const userContainerElement = this.$refs.userContainer;

      // after first render the user container gets populated on refresh
      if (userContainerElement.offsetWidth !== this.userContainerWidth) {
        this.validateViewType();
        this.userContainerWidth = userContainerElement.offsetWidth;
      }
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.validateViewType);
      window.removeEventListener('beforeunload', this.destroyComponent);
    },
  };
</script>

<style scoped>
/* Header container */
.header-container {
  width: 82%;
  max-width: 1222px;
  margin: 0 auto;
  overflow: visible;
}

@media all and (max-width: 480px) {
  .header-container {
    width: 100%;
  }
}

/* Inner Header container - contains menu elements */
.main-header {
  background-color: #3c3a4c;
  font-size: 14px;
  position: relative;
  z-index: 2;
  height: 60px;
  margin-bottom: 75px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

@media all and (max-width: 480px) {
  .main-header {
    margin-bottom: 0;
  }
}

.main-header * {
  position: relative;
  z-index: 1;
}

.main-header::before {
  content: ' ';
  display: block;
  background-image: url(../assets/images/svg/logo-bg.svg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  z-index: 0;
  position: absolute;
  left: -47px;
  top: 0;
  width: 140%;
  height: 100%;
}

.main-header::after {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(../assets/images/svg/menu-right-bg.svg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;
  top: 0;
  right: -47px;
  z-index: 0;
}

/* Logo */
.logo {
  float: left;
  height: 100%;
  min-width: 114px;
  max-width: 140px;
  padding: 10px 20px;
  position: relative;
  background-color: #2d2d37;
}

.no-csstransforms .logo {
  display: block;
  width: 100px;
  height: 70px;
  padding: 20px;
}

.logo img {
  display: block;
  max-width: 100%;
  height: 40px;
}

/* Menu */
.nav-menu {
  flex: 7;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
}

.UniSansBook-loaded .nav-menu {
  font-family: 'UniSansBook';
}

.nav-menu li {
  float: left;
  margin: 0;
}

.nav-menu li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px 25px 13px;
  border-right: 1px solid #2d2d37;
  border-bottom: 5px solid transparent;
  transition: border-bottom-color 0.2s linear;
  color: white;
}

.nav-menu li a:hover,
.nav-menu li a:active,
.nav-menu li a:focus {
  border-bottom: 5px solid #00c8e6;
}

.nav-menu li.current a {
  border-bottom: 5px solid #00c8e6;
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 500px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.nav-menu-mobile {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  background-color: inherit;
  border-top: 1px solid #2d2d37;
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.1);
  max-height: 500px;
  overflow-y: auto;
}

.UniSansBook-loaded .nav-menu-mobile {
  font-family: 'UniSansBook';
}

.nav-menu-mobile ul {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-menu-mobile li {
  width: 100%;
  text-transform: none;
  margin: 0;
}

.nav-menu-mobile li a {
  display: block;
  height: 100%;
  border-right: 1px solid #2d2d37;
  border-bottom: 5px solid transparent;
  transition: border-bottom-color 0.2s linear;
  color: white;
  border: none !important;
  padding: 10px 20px !important;
}

.nav-menu-mobile li a:hover,
.nav-menu-mobile li a:active,
.nav-menu-mobile li a:focus {
  background-color: #00c8e6;
  transition: background-color 0.2s linear;
}

.nav-menu-mobile li.current a {
  background-color: #00c8e6;
  transition: background-color 0.2s linear;
}

.menu-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex: 7;
}

.menu-button-container.mobile {
  justify-content: flex-start;
}

.menu-button {
  color: white;
  background: none;
  border: none;
  padding: 18px 20px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s linear;
  border-radius: 5px;
}

.UniSansBook-loaded .menu-button {
  font-family: 'UniSansBook';
}

.menu-button:hover,
.menu-button:focus,
.menu-button:active {
  background-color: #00c8e6;
  outline: none;
}

@media all and (max-width: 985px) {
  .menu-button {
    display: block;
    float: left;
    position: relative;
    top: 2px;
  }
}

@media all and (max-width: 350px) {
  .menu-button {
    padding: 10px;
    top: 10px;
    width: 40px;
    height: 30px;
    overflow: hidden;
  }
}

.hamburger-icon {
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

/* User information menu - email and login/logout button */
.user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 3;
}

.user-account p {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 4px;
}

/* User Email */
.user-email {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 12px;
  color: white;
}

.user-email:not(.disabled) p:hover {
  border-bottom: 5px solid #00c8e6;
  padding-bottom: 5px;
}

.user-email p {
  font-family: 'UniSansBook';
  text-transform: lowercase;
}

@media all and (max-width: 640px) {
  .user-email p {
    display: none;
  }
}

.user-image {
  width: 35px;
  min-width: 35px;
  margin-right: 6px;
}

/* User login/logout button */
.login-logout-button {
  float: right;
  text-transform: uppercase;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  transition: all 0.2s linear;
  padding: 6px 20px;
  font-size: 13px;
  margin: 12.5px 10px 12.5px 0;
}

@media all and (max-width: 480px) {
  .login-logout-button {
    margin-right: 20px;
  }
}

@media all and (max-width: 350px) {
  .login-logout-button {
    padding: 3px 15px;
    font-size: 12px;
    margin-top: 15px;
  }
}

.login-logout-button:hover,
.login-logout-button:active,
.login-logout-button:focus {
  color: #2d2d37;
  border-color: white;
  background-color: white;
}
</style>
