<template>
  <footer class="container container--footer">
    <div class="wrapper wrapper--footer">
      <div class="main-footer clearfix">
        <div class="footer__modules clearfix">
          <div class="footer__col footer__col-one">
            <img src="../assets/images/svg/logo.svg" class="logo--footer" id="footer-logo"
              onerror="this.src='../assets/images/fallback/logo.png'; this.onerror = null" alt="COBI.bike"
              aria-label="COBI.bike" role="logo" />
          </div>
        </div>
        <div v-if="showFootnote" class="footer__copyright">
          <span v-html="$t('footer.copyright-company', { currentYear: currentYear })"></span>
          <a class="footer__corporate" :href="$t('footer.corporate.link')">
            {{ ` ${$t('footer.corporate.text')} ` }} </a>,
          <a class="footer__privacy" :href="$t('footer.privacy.link')">
            {{ ` ${$t('footer.privacy.text')} ` }} </a>,
          <a class="footer__privacy-regional-en" href="https://cdn.cobi.bike/static/legal/Privacy_Policy_US_CA_EN.pdf"
            target="_blank">
            {{ ` ${$t('footer.privacy-regional.text-en')} ` }}
          </a>
          <a class="footer__privacy-regional-fr" href="https://cdn.cobi.bike/static/legal/Privacy_Policy_CA_FR.pdf"
            target="_blank">
            {{ ` ${$t('footer.privacy-regional.text-fr')} ` }} </a>,
          <a class="footer__terms" :href="$t('footer.terms.link')">
            {{ ` ${$t('footer.terms.text')} ` }} </a>,
          <a class="footer__service-description" :href="$t('footer.service-description.link')">
            {{ ` ${$t('footer.service-description.text')} ` }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'cobi-footer',
    props: ['showFootnote', 'isBoschIdEnabled'],
    setup() {
      const currentYear = new Date().getFullYear();

      return {currentYear };
    }
  };
</script>

<style>
.container--footer {
  background-color: #2d2d37;
}

.wrapper--footer {
  padding-bottom: 0;
}

.main-footer {
  font-weight: 300;
  font-family: Helvetica, Arial, sans-serif;
  color: white;
}

.UniSansBook-loaded .main-footer {
  font-family: 'UniSansBook';
}

.main-footer ul:not(.footer__social-list) {
  margin-top: 30px;
}

.main-footer ul:not(.footer__social-list) li::before {
  content: '>';
  color: #00c8e6;
  margin-right: 5px;
  font-weight: 300;
}

.main-footer ul:not(.footer__social-list) li a {
  border-bottom: 1px dotted transparent;
  -webkit-transition: border-color 0.1s linear;
  -ms-transition: border-color 0.1s linear;
  transition: border-color 0.1s linear;
}

.main-footer ul:not(.footer__social-list) li a:hover,
.main-footer ul:not(.footer__social-list) li a:focus,
.main-footer ul:not(.footer__social-list) li a:active {
  border-color: #eee;
}

.footer__social-list {
  margin-top: 10px;
  margin-bottom: -20px;
}

.footer__social-list li {
  display: inline-block;
}

.footer__social-list li svg .social-icon-bg {
  fill: #00c8e6;
  transition: fill 0.2s linear;
}

.footer__social-list li a:hover .social-icon-bg,
.footer__social-list li a:focus .social-icon-bg,
.footer__social-list li a:active .social-icon-bg {
  fill: #019cb3;
}

.footer__col {
  float: left;
  padding: 30px;
}

.footer__col-one {
  width: 15%;
  padding: 30px 0;
}

.footer__col-two {
  width: 30%;
}

.footer__col-three {
  width: 40%;
}

.footer__col-four {
  width: 15%;
}

@media all and (max-width: 996px) {
  .footer__col-one {
    width: 100%;
    padding: 30px;
  }

  .footer__col-three {
    width: 50%;
  }

  .footer__col-four {
    width: 20%;
  }
}

@media all and (max-width: 750px) {
  .main-footer {
    text-align: center;
  }

  .footer__col {
    width: 100%;
  }

  li {
    display: inline-block;
  }

  .footer__col {
    padding-top: 0;
  }
}

.logo--footer {
  width: 125px;
}

.newsletter {
  position: relative;
  margin-top: 20px;
}

.newsletter__input {
  padding: 0.7em 1.5em;
  font-size: 14px;
  border: 1px solid #00c8e6;
  border-radius: 40px;
  font-weight: 300;
  background-color: transparent;
  color: white;
  float: left;
  width: 100%;
  padding-right: 120px;
  height: 40px;
  font-family: Helvetica, Arial, sans-serif;
}

.newsletter__input:focus {
  outline: none;
}

.UniSansBook-loaded .newsletter__input {
  font-family: 'UniSansBook';
}

.newsletter__submit-button {
  float: left;
  padding: 0.7em 1em;
  font-size: 14px;
  border: 1px solid #00c8e6;
  border-radius: 0 40px 40px 0;
  font-weight: 300;
  background-color: #00c8e6;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  width: 120px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s linear;
  font-family: Helvetica, Arial, sans-serif;
}

.newsletter__submit-button:hover,
.newsletter__submit-button:active,
.newsletter__submit-button:focus {
  outline: none;
  background-color: #019cb3;
  border-color: #019cb3;
}

.UniSansBook-loaded .newsletter__submit-button {
  font-family: 'UniSansBook';
}

.footer__copyright {
  background: transparent url('../assets/images/svg/dotted-line.svg') center top no-repeat;
  background-size: auto auto;
  color: #94949c;
  text-align: center;
  padding: 15px 30px;
  font-size: 14px;
}

.footer__copyright a {
  border-bottom: 1px solid;
}

@media all and (max-width: 480px) {
  .main-footer {
    padding-bottom: 100px;
  }
}
</style>
