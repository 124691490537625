<template>
  <div
    :class="[variant, colorScheme, { open: isOpen }]"
    class="dropdown"
    v-click-outside="hideOptions"
  >
    <div class="dropdown-label-container" @click="toggleDropdown">
        <span class="dropdown-label">
            {{ selectedValue ? selectedValue : defaultLabelText }}
        </span>
        <img
          alt="dropdown"
          class="dropdown-label-arrow"
          src="../assets/images/arrow-down.svg"
        />
    </div>
    <div class="dropdown-options">
        <div
            v-for="option in options"
            :key="option"
            class="dropdown-option"
            @click="selectOption(option)"
        >
            {{ option }}
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'cobi-dropdown',
    props: ['options', 'selectedValue', 'onValueChange', 'defaultLabelText', 'variant', 'colorScheme'],
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      hideOptions() {
        this.isOpen = false;
      },
      selectOption(optionValue) {
        this.onValueChange(optionValue);
        this.toggleDropdown();
      },
    },
  };
</script>

<style>
    .dropdown {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .dropdown-label-container {
      background-color: transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      width: 100%;
      border-bottom: 1px solid #fff;
      cursor: pointer;
    }

    .dropdown.rounded .dropdown-label-container {
      border-radius: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .dropdown.light .dropdown-label-container {
      background-color: #fff;
    }

    .dropdown-label-arrow {
      transition: transform 0.25s ease;
    }

    .dropdown.light .dropdown-label-arrow {
      filter: invert(1);
    }

    .dropdown.open .dropdown-label-arrow {
      transform: rotate(180deg);
    }

    .dropdown-options {
      width: 100%;
      margin-top: 5px;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.25s ease;
      overflow-y: auto;
      max-height: 100px;
      background-color: #fff;
      color: #000;
    }

    .dropdown.rounded .dropdown-options {
      border-radius: 20px;
    }

    .dropdown.open .dropdown-options {
      transform: scaleY(1);
      position: static;
    }

    .dropdown-option {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      cursor: pointer;
    }

    .dropdown-option:hover {
      background-color: #F3F3F3;
    }
</style>
