import {
  GuardsCheck, isLoggedIn, hasRole, customerServiceRedirect,
} from '../common/router/guards';
import Firmware from '../pages/Firmware.vue';
import HubStatus from '../pages/HubStatus.vue';
import AccountInformation from '../pages/AccountInformation.vue';
import BuildInformation from '../pages/BuildInformation.vue';
import ViewLogs from '../pages/ViewLogs.vue';
import CobiCountries from '../pages/CobiCountries.vue';
import Admin from '../pages/Admin.vue';
import OEM from '../pages/OEM.vue';

const routes = [
  {
    path: '/firmware',
    name: 'firmware',
    component: Firmware,
    beforeEnter: GuardsCheck([isLoggedIn, hasRole('firmware_manager')]),
  },
  {
    path: '/hubstatus',
    name: 'hubstatus',
    component: HubStatus,
    beforeEnter: GuardsCheck([isLoggedIn, hasRole('cobi')]),
  },
  {
    path: '/account-information',
    name: 'account_information',
    component: AccountInformation,
    beforeEnter: GuardsCheck([isLoggedIn, hasRole('cobi')]),
  },
  {
    path: '/loggedin',
    beforeEnter: GuardsCheck([isLoggedIn, customerServiceRedirect]),
  },
  {
    path: '/build-information',
    name: 'build_information',
    component: BuildInformation,
  },
  {
    path: '/view-logs',
    name: 'view_logs',
    component: ViewLogs,
    beforeEnter: GuardsCheck([isLoggedIn, hasRole('team')]),
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    beforeEnter: GuardsCheck([isLoggedIn, hasRole('maintainer')]),
  },
  {
    path: '/oem',
    name: 'oem',
    component: OEM,
    beforeEnter: GuardsCheck([isLoggedIn, hasRole('oem')]),
  },
  {
    path: '/cobi-countries',
    name: 'cobi_countries',
    component: CobiCountries,
    beforeEnter: GuardsCheck([isLoggedIn, hasRole('active_countries')]),
  },
];

export default routes;
