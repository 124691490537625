<template>
  <div>
    <div v-if="showCheckbox" class="input-container">
      <input
        type="checkbox"
        :name="fieldName"
        :id="fieldName"
        :checked="checked"
        @change="$emit('valueChanged', $event.target.checked, fieldName)"
      />
      <label
        class="label"
        :for="fieldName"
      ></label>
    </div>

    <div v-if="!showCheckbox">{{cellValue}}</div>
  </div>
</template>

<script>
  export default {
    name: 'checkbox-cell',
    props: ['checked', 'fieldName', 'cellValue', 'showCheckbox'],
  };
</script>

<style scoped>
  .input-container {
    height: 30px;
  }

  .label {
    height: 100%;
    margin-bottom: 0;
  }
</style>
