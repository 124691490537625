<template>
    <div class="container">
      <h2>{{ $t('admin.title') }}</h2>
      <div class="token-container">
        <button
          class="button button--filled--blue token-button"
          @click="getAccessToken"
        >
          {{ $t('admin.retrieve_token_button') }}
        </button>
        <button
          class="button button--filled--blue copy-to-clipboard-button"
          @click="copyToClipboard"
          :disabled="!accessToken"
        >
          {{ $t('admin.copy_to_clipboard_button') }}
        </button>
      </div>
    </div>
</template>

<script>
  import auth from '../../common/auth';
  import emitter from '../../utils/emitter';

  export default {
    name: 'access-token',
    data() {
      return {
        accessToken: null,
      };
    },
    methods: {
      async getAccessToken() {
        try {
          const response = await auth.doAuthenticatedGet('customerservice/admin/token');

          if (response.token === null) {
            emitter.emit('display-error-message', this.$i18n.t('admin.access_token_not_saved'));
            this.accessToken = null;
            return;
          }

          this.accessToken = response.token;
          emitter.emit('display-success-message', this.$i18n.t('admin.retrieve_success_message'));
        } catch (err) {
          emitter.emit('display-error-message');
        }
      },
      async copyToClipboard() {
        try {
          if (!window.navigator || !window.navigator.clipboard) {
            emitter.emit('display-error-message', this.$i18n.t('admin.clipboard_not_supported_error_message'));
            return;
          }

          await navigator.clipboard.writeText(this.accessToken);
          emitter.emit('display-success-message', this.$i18n.t('admin.copied_to_clipboard_success_message'));
        } catch (err) {
          emitter.emit('display-error-message');
        }
      },
    },
  };
</script>

<style scoped>
  .container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin: 0 60px;
  }

  .token-container {
    width: 90%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .token-button {
    margin-bottom: 50px;
  }

  .token-button,
  .copy-to-clipboard-button {
    width: 300px;
  }
</style>
