<template>
    <div class="logs__table-container">
      <div
        class="logs__table-count-header"
        v-if="showTableHeader"
      >
        <button class="button button--filled--blue filter-button"
          :class='{active: showFilters}'
          aria-label="Toggle Filters"
          @click="toggleFilters">
          {{ $t("log_table.filters") }}

          <img
            class="filter-icon"
            src="../../common/assets/images/svg/filter-icon.svg"
          />
        </button>

        <div class="entries">
          <div class="lead">
            {{ $t("log_table.header_entry_show") }}
            {{ logs.length }}
            {{ $t("log_table.header_entry_of") }}
            {{ totalEntries }}
            {{ $t("log_table.header_entry_total") }}
          </div>
          <div class="separator"></div>
          <div class="lead logs__table-count-select">
              <span>{{ $t("log_table.header_entry_per_page") }}</span>
              <input
                id="entry-number-input"
                type="number"
                class="number-input"
                required
                min="1"
                max="100"
                step="1"
                v-model="entryNumber"
                @change="onEntryNumberInputChange($event)"
              />
          </div>
        </div>
      </div>

      <table-filters
        :showFilters="showFilters"
        :filters="filters"
        :logTypes="logTypes"
        :platforms="platforms"
        @clearFilters="clearFilters"
        @applyFilters="applyFilters"
        @changeOs="onChangeOs"
        @changeLogType="onChangeLogType"
      >
      </table-filters>

      <div class="generic-table-container">
        <generic-table
          :rows="logs"
          :columns="columns"
          :loading="loading"
          :noResultsText="$t('log_table.no_logs_notification')"
          :loadingText="$t('log_table.loading_logs')"
        >
          <template v-slot:actionsHeader>
            <div class="cell actions-field flex-3"></div>
          </template>

          <template
            v-slot:actionsBody="{row}"
            :downloadLog="downloadLog"
            :deleteLog="deleteLog"
          >
            <div class="cell actions-field flex-3">
              <button
                :id="`download-log-button-${row.id}`"
                class="download-log-button"
                aria-expanded="false"
                aria-haspopup="true"
                aria-label="Download Log"
                @click="downloadLog(row)"
              >
                <img
                  class="button-icon"
                  src="../../common/assets/images/svg/download-icon.svg"
                />
                <img
                  class="button-icon"
                  src="../../common/assets/images/svg/download-icon-hover.svg"
                />
              </button>
              <button
                :id="`delete-log-button-${row.id}`"
                class="delete-log-button"
                aria-expanded="false"
                aria-haspopup="true"
                aria-controls="del-log-confirmation-prompt"
                aria-label="Delete Log"
                @click="deleteLog($event, row)"
              >
                <img
                  class="button-icon"
                  src="../../common/assets/images/svg/delete-icon.svg"
                />
                <img
                  class="button-icon"
                  src="../../common/assets/images/svg/delete-icon-hover.svg"
                />
              </button>
            </div>
          </template>

          <template
            v-slot:customRowActions="{row}"
            :cancelDeleteLog="cancelDeleteLog"
            :confirmDeleteLog="confirmDeleteLog"
          >
            <transition name="fade">
              <div
                class="del-log-confirmation-prompt"
                v-if="row._deleteConfirmation"
                :id="`del-log-confirmation-prompt-${row.id}`"
                role="alert"
                tabindex="-1"
              >
                <div>
                  <p>{{ $t("log_table.delete_log_text1") }}</p>
                  <footer>
                    <button
                      class="button button--confirmation button--white"
                      :id="`cancel-del-log-button-${row.id}`"
                      @click="cancelDeleteLog($event, row)"
                    >
                      {{ $t("log_table.delete_log_cancel_button") }}
                    </button>
                    <button
                      class="button button--confirmation button--coral"
                      :id="`confirm-del-log-button-${row.id}`"
                      @click="confirmDeleteLog($event, row)"
                    >
                      {{ $t("log_table.delete_log_confirm_button") }}
                    </button>
                  </footer>
                </div>
              </div>
            </transition>
          </template>
        </generic-table>
        <div
          class="lead logs__table-page-navigation"
          v-if="logs.length != 0 && !loading"
        >
          <button
            id="first-page-button"
            aria-label="First Page"
            :class="[
              { 'button-inactive': isNotValidPageNumber },
              'logs__table-nav-button', 'button-start'
            ]"
            @click="getPage(false, 1)"
          >
            <img
              id="first-page-icon"
              src="../../common/assets/images/svg/arrow-first.svg"
              :class="{ 'icon-inactive': isNotValidPageNumber }"
            >
          </button>
          <button
            aria-label="Get Previous Page"
            id="previous-page-button"
            :class="[
              { 'button-inactive': isNotValidPageNumber },
              'logs__table-nav-button', 'button-start'
            ]"
            @click="getPage(false, pageNumber - 1)"
          >
            <img
              id="previous-page-icon"
              src="../../common/assets/images/svg/arrow-previous.svg"
              :class="{ 'icon-inactive': isNotValidPageNumber }"
            >
          </button>
          <span>{{ $t("log_table.page_count") }}</span>
          <input
            id="page-number-input"
            type="number"
            class="number-input logs__table-navigation-input"
            min="1"
            :max="totalPageCount"
            step="1"
            v-model="pageNumber"
            @change="onPageNumberInputChange($event)"
          />
          <span>
            {{ $t("log_table.page_of") }} {{ totalPageCount }}
          </span>
          <button
            id="next-page-button"
            aria-label="Get Next Page"
            :class="[
              { 'button-inactive': isLastPageNumber },
              'logs__table-nav-button', 'button-start'
            ]"
            @click="getPage(false, pageNumber + 1)"
          >
            <img
              id="next-page-icon"
              src="../../common/assets/images/svg/arrow-next.svg"
              :class="{ 'icon-inactive': isLastPageNumber }"
            >
          </button>
          <button
            aria-label="Get Last Page"
            id="last-page-button"
            :class="[
              { 'button-inactive': isLastPageNumber },
              'logs__table-nav-button', 'button-start'
            ]"
            @click="getPage(false, totalPageCount)"
          >
            <img
              id="last-page-icon"
              src="../../common/assets/images/svg/arrow-last.svg"
              :class="{ 'icon-inactive': isLastPageNumber }"
            >
          </button>
        </div>
      </div>
    </div>
</template>

<script>
  import auth from '../../common/auth';
  import formatDate from '../../common/utils/formatDate';
  import TableFilters from './TableFilters.vue';
  import Table from '../../common/components/Table.vue';
  import emitter from '../../utils/emitter';

  export default {
    name: 'logs-table',
    components: {
      'table-filters': TableFilters,
      'generic-table': Table,
    },
    data() {
      return {
        columns: [
          {
            name: this.$i18n.t('log_table.table_log_id'),
            accessor: 'id',
            class: 'flex-5',
          },
          {
            name: this.$i18n.t('log_table.table_platform'),
            accessor: 'platform',
            class: 'flex-3',
            defaultValue: '-',
          },
          {
            name: this.$i18n.t('log_table.table_creation_date'),
            accessor: 'timestamp',
            class: 'flex-4',
          },
          {
            name: this.$i18n.t('log_table.table_identifier_label'),
            accessor: 'identifier',
            class: 'flex-5',
            defaultValue: '-',
          },
          {
            name: this.$i18n.t('log_table.table_file_name'),
            accessor: 'fileName',
            class: 'flex-5',
          },
          {
            name: this.$i18n.t('log_table.table_file_size'),
            accessor: 'fileSize',
            class: 'flex-3',
          },
          {
            name: this.$i18n.t('log_table.table_log_type'),
            accessor: 'logType',
            class: 'flex-3',
          },
        ],
        logs: [],
        loading: true,
        entryNumber: 15,
        totalEntries: 0,
        pageNumber: 1,
        MAX_ENTRIES: 200,
        showFilters: false,
        platforms: [],
        logTypes: [],
        filters: {
          logId: '',
          platform: '',
          identifier: '',
          startDate: '',
          endDate: '',
          fileName: '',
          minFileSize: '',
          maxFileSize: '',
          logType: '',
        },
      };
    },
    computed: {
      totalPageCount() {
        return Math.ceil(this.totalEntries / this.entryNumber);
      },
      isNotValidPageNumber() {
        return this.pageNumber <= 1 || this.pageNumber.length === 0;
      },
      isLastPageNumber() {
        return this.pageNumber === this.totalPageCount;
      },
      showTableHeader() {
        if (Object.values(this.filters).length) {
          return true;
        }

        return this.logs.length !== 0 && !this.loading;
      },
    },
    methods: {
      async getLogsFromDB() {
        await this.getPage(true, 1);
      },

      async getPage(isFirstRequest, pageNumber) {
        let path = '';

        this.pageNumber = pageNumber;

        this.loading = true;

        if (isFirstRequest) {
          path = 'logs/general';
        } else {
          path = `logs/general?page=${(this.pageNumber - 1)}&pageSize=${this.entryNumber}`;
        }

        const filtersQuery = Object.keys(this.filters).reduce((previousValue, currentValue) => {
          if (!this.filters[currentValue]) {
            return previousValue;
          }

          // The - will be added only for platform field
          let filterValue = this.filters[currentValue] === '-' ? 'null' : this.filters[currentValue];

          // Transform KB to bytes (in DB we store bytes)
          if (['minFileSize', 'maxFileSize'].includes(currentValue)) {
            filterValue *= 1024;
          }

          return `${previousValue}&${currentValue}=${filterValue}`;
        }, '');

        path += filtersQuery;

        try {
          const entries = await auth.doAuthenticatedGet(path);
          this.loading = false;

          if (entries.rows !== []) {
            this.totalEntries = entries.count;
            this.logs = [];
            entries.rows.forEach((log) => {
              this.addLog(log);
            });
          }
        } catch (err) {
          console.log(err);
          this.loading = false;
          emitter.emit('display-error-message');
        }
      },

      addLog(log) {
        const d = new Date(log.timestamp);

        this.logs.push({
          id: log.id,
          platform: log.platform,
          timestamp: formatDate(d),
          identifier: this.checkLogIndentifier(log.userId, log.deviceId),
          fileName: log.fileName,
          fileSize: parseFloat(log.fileSize / 1024).toFixed(2),
          logType: log.logType,
          _deleteConfirmation: false,
        });
      },

      checkLogIndentifier(userId, deviceId) {
        return userId || deviceId;
      },

      onEntryNumberInputChange(event) {
        if (event.target.value === '') {
          this.entryNumber = 1;
        } else if (this.entryNumber < 1) {
          this.entryNumber = 1;
        } else if (this.entryNumber > this.MAX_ENTRIES) {
          this.entryNumber = this.MAX_ENTRIES;
        }

        if (this.entryNumber >= 1 && this.entryNumber <= this.MAX_ENTRIES) {
          this.entryNumber = Math.ceil(this.entryNumber);
          this.pageNumber = 1;

          setTimeout(async () => {
            await this.getPage(false, this.pageNumber);
          }, 500);
        }
      },

      onPageNumberInputChange(event) {
        if (event.target.value === '') {
          this.pageNumber = 1;
        } else if (this.pageNumber < 1) {
          this.pageNumber = 1;
        } else if (this.pageNumber > this.totalPageCount) {
          this.pageNumber = this.totalPageCount;
        }

        if (this.pageNumber >= 1 && this.pageNumber <= this.totalPageCount) {
          this.pageNumber = Math.ceil(this.pageNumber);

          setTimeout(async () => {
            await this.getPage(false, this.pageNumber);
          }, 500);
        }
      },

      async downloadLog(log) {
        try {
          const logResponse = await auth.doAuthenticatedGetWithHeaders(`logs/general/${log.id}`);

          if (logResponse === null) {
            emitter.emit('display-error-message', this.$i18n.t('No log file found'));
            return;
          }

          const { headers } = logResponse;
          const blob = new Blob([logResponse.data], { type: headers['content-type'] });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          let logFileName = '';
          const disposition = headers['content-disposition'];
          if (disposition) {
            if (disposition.indexOf('attachment') !== -1) {
              const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              const matches = fileNameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                logFileName = matches[1].replace(/['"]/g, '');
              }
            }
          }

          link.download = logFileName;
          link.click();
        } catch (err) {
          console.log(err);
          emitter.emit('display-error-message');
        }
      },

      deleteLog(event, log) {
        const deleteButton = event.target;

        // shows confirmation prompt
        // eslint-disable-next-line no-param-reassign
        log._deleteConfirmation = true;

        deleteButton.setAttribute('aria-expanded', 'true');

        const row = deleteButton.closest('div.row');

        setTimeout(() => {
          row.style.backgroundColor = '#2d2d37';
          row.style.color = '#fff';
        }, 150);

        // using visiblity instead of display none because we want the screen reader to be able to
        // read the aria-expanded = true information
        deleteButton.style.visibility = 'hidden';
      },

      cancelDeleteLog(event, log) {
        const cancelButton = event.target;
        const rowContainer = cancelButton.closest('div.row-container');
        const row = rowContainer.querySelector('div.row');
        const rowDeleteButton = rowContainer.querySelector('.delete-log-button');
        const confirmation = rowContainer.querySelector('.del-log-confirmation-prompt');

        confirmation.classList.add('hide');
        row.style.backgroundColor = '';
        row.style.color = '';
        rowDeleteButton.style.visibility = 'visible';
        rowDeleteButton.setAttribute('aria-expanded', 'false');

        // eslint-disable-next-line no-param-reassign
        log._deleteConfirmation = false;
      },

      async confirmDeleteLog(event, log) {
        try {
          const confirmDeleteButton = event.target;
          confirmDeleteButton.classList.add('loading');
          const row = confirmDeleteButton.closest('div.row-container');

          await auth.doAuthenticatedDelete(`logs/general/${log.id}`);

          row.classList.add('hide');
          emitter.emit('display-success-message');

          const pageNumber = this.isLastPageNumber && this.pageNumber !== 1
            ? this.pageNumber - 1
            : this.pageNumber;
          await this.getPage(false, pageNumber);
        } catch (error) {
          console.log(error);
          emitter.emit('display-error-message');
        }
      },

      toggleFilters() {
        this.showFilters = !this.showFilters;
      },

      async clearFilters() {
        this.showFilters = false;

        this.filters = Object.keys(this.filters).reduce((previousValue, currentValue) => {
          const newFilters = { ...previousValue };
          newFilters[currentValue] = '';

          return newFilters;
        }, {});

        try {
          await this.getPage(false, this.pageNumber);
        } catch (error) {
          emitter.emit('display-error-message');
        }
      },

      async applyFilters() {
        this.showFilters = false;

        try {
          await this.getPage(false, this.pageNumber);
        } catch (error) {
          emitter.emit('display-error-message');
        }
      },

      onChangeOs(os) {
        this.filters.platform = os;
      },

      onChangeLogType(logType) {
        this.filters.logType = logType;
      },

      async getLogTypes() {
        try {
          const logTypes = await auth.doAuthenticatedGet('logs/types');

          this.logTypes = Object.values(logTypes);
        } catch (_) {
          emitter.emit('display-error-message');
        }
      },

      async getPlatforms() {
        try {
          const platforms = await auth.doAuthenticatedGet('logs/platforms');

          this.platforms = ['-', ...Object.values(platforms)];
        } catch (_) {
          emitter.emit('display-error-message');
        }
      },
    },

    async mounted() {
      await this.getLogsFromDB();
      await this.getLogTypes();
      await this.getPlatforms();
    },
  };
</script>

<style scoped>
  /* remove spinner arrows from input of type number */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
      -moz-appearance: textfield;
  }

  .fade-enter-active, .fade-leave-active  {
    max-height: 310px;
    overflow-y: hidden;
    -webkit-transition: max-height 0.4s linear;
    -moz-transition: max-height 0.4s linear;
    -o-transition: max-height 0.4s linear;
    transition: max-height 0.4s linear;
  }
  .fade-enter, .fade-leave-to {
    max-height: 0px ;
  }

  .delete-log-button {
    float: right;
    margin-right: 5px;
    background: none;
    border: none;
    max-width: 100%;
  }

  .download-log-button {
    float: right;
    margin-right: 5px;
    background: none;
    border: none;
    max-width: 100%;
  }

  .logs__table-container {
    width: 92%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .logs__table-count-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .logs__table-count-header .entries {
    display: flex;
    align-items: center;
  }

  .separator {
    height: 30px;
    width: 2px;
    background-color: #2d2d37;
    margin: 0 20px;
  }

  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    color: #2d2d37;
    box-shadow: 0px 2px 5px #1016179e;
    border: none;
    text-transform: none;
  }

  .filter-button.active {
    color: #fff;
    background-color:  #2d2d37;
    -webkit-transition: color 0.2s linear;
    -moz-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear;
    border: none;
  }

  .filter-icon {
    width: 20px;
    height: 15px;
    margin-left: 5px;
  }

  .filter-button.active .filter-icon {
    filter: invert(1);
  }

  .logs__table-page-navigation {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      margin-top: 40px;
  }

   .logs__table-navigation-input {
      margin-right: 5px;
  }

  .logs__table-nav-button {
    height: 30px;
    width: 30px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-start {
    margin-right: 5px;
  }

   .button-previous {
    margin-right: 10px;
  }

   .button-next {
    margin-left: 10px;
  }

   .button-end {
    margin-left: 5px;
  }

  .download-log-button {
    position: relative;
    transition: all .2s linear;
  }
  .download-log-button img:last-child {
    display: none;
  }
  .download-log-button:hover img:last-child,
  .download-log-button:active img:last-child {
   display: block;
  }
  .download-log-button:hover img:first-child,
  .download-log-button:active img:first-child {
   display: none;
  }

  .delete-log-button {
    position: relative;
    transition: all .2s linear;
  }
  .delete-log-button img:last-child {
    display: none;
  }
  .delete-log-button:hover img:last-child,
  .delete-log-button:active img:last-child,
  .delete-log-button:focus img:last-child {
   display: block;
  }
  .delete-log-button:hover img:first-child,
  .delete-log-button:active img:first-child,
  .delete-log-button:focus img:first-child {
   display: none;
  }

  .button-inactive {
    pointer-events: none;
  }

  .icon-inactive {
     filter: invert(60%) sepia(6%) saturate(16%) hue-rotate(326deg) brightness(98%) contrast(92%);
  }

  .button-icon {
    pointer-events: none;
    width: 42px;
    height: 42px;
    object-fit: contain;
  }

  .del-log-confirmation-prompt {
    overflow: hidden;
    background-color: #2d2d37;
    width: 100% !important;
    display: block;
    color: #fff;
    padding: 10px 20px 20px !important;
  }
  .del-log-confirmation-prompt .button--coral {
    float: right;
  }

  .logs__notification {
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    clear: both;
    box-shadow: inset 0 0 2px #e3e3e3;
  }

  .lead {
    padding: 0 !important;
  }

  .number-input {
    width: 45px;
    height: 30px;
    margin-left: 5px;
    padding: 5px 5px 0px;
    font-size: 1.125rem;
    line-height: 1.6;
    font-weight: 300;
    text-align: end;
    background-color: transparent;
  }

  .generic-table-container {
    width: 100%;
    margin-top: 20px;
  }
</style>
