<template>
  <transition name="fade">
    <div
      class="account-confirmation-prompt"
      v-if="isVisible"
      role="alert"
      tabindex="-1"
    >
    <p>{{ title }}</p>

    <slot name="customContent"></slot>

    <footer class="account-confirmation-prompt-footer">
      <button
        class="button button--confirmation button--white"
        :id="cancelId"
        @click.prevent="$emit('cancel')"
      >
        {{ cancelText }}
      </button>
      <button
        class="button button--confirmation button--coral"
        :id="confirmId"
        :disabled="confirmDisabled"
        @click.prevent="$emit('confirm')"
      >
        {{ confirmText }}
      </button>
    </footer>
    </div>
  </transition>
</template>

<script>
  export default {
    props: ['title', 'isVisible', 'cancelText', 'confirmText', 'confirmId', 'cancelId', 'confirmDisabled'],
  };
</script>

<style scoped>
   .account-confirmation-prompt {
    overflow: hidden;
    background-color: #2d2d37;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 10px 20px 20px !important;
  }

  .fade-enter-active, .fade-leave-active  {
    max-height: 310px;
    overflow-y: hidden;
    -webkit-transition: max-height 0.1s linear;
    -moz-transition: max-height 0.1s linear;
    -o-transition: max-height 0.1s linear;
    transition: max-height 0.1s linear;
  }

  .fade-enter, .fade-leave-to {
    max-height: 0px ;
  }

  .account-confirmation-prompt .button--coral {
    float: right;
  }

  .account-confirmation-prompt-footer {
    margin-top: 10px;
    width: 100%;
  }

  .account-confirmation-prompt-dropdowm-container {
    width: 250px;
  }
</style>
