import getDomain from './getDomain';
/**
 * Sets a cookie
 *
 * @param {string} name cookie name
 * @param {string} value cookie value
 *  @param {number}  days cookie expiry days
 */

export function setCookie(name, value, days) {
  const rootDomain = getDomain();
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ''}; domain=${rootDomain}${expires}; path=/`;
}

export function getCookie(name) {
  // e.g firstCookieSection = 'accessToken='
  const firstCookieSection = `${name}=`;
  const cookiesArray = document.cookie.split(';');
  for (let i = 0; i < cookiesArray.length; i += 1) {
    let cookie = cookiesArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(firstCookieSection) === 0) {
      return cookie.substring(firstCookieSection.length, cookie.length);
    }
  }
  return null;
}

export function eraseCookie(name) {
  const rootDomain = getDomain();
  // seting expiry date in the past is the way to remove a cookie
  document.cookie = `${name}=; domain=${rootDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
