<template>
  <div>
    <slot
      :title="$t('customer-service.page-title')"
      :withError="true"
      :withSuccess="true"
      :controlled="true"
      :error="alerts.error"
      :withTranslation="true"
      :success="alerts.success"
    >
    </slot>
    <div class="content-wrapper">
      <section class="content-section text-center">
        <h1>{{ $t('cs_admin.title') }}</h1>
      </section>
      <section class="landing__container__account content-section text-center">
        <access-token></access-token>
      </section>
    </div>
  </div>
</template>

<script>
  import AccessToken from '../components/Admin/AccessToken.vue';

  export default {
    name: 'view-logs',
    data() {
      return {
        alerts: {
          error: null,
          success: null,
        },
      };
    },
    components: {
      'access-token': AccessToken,
    },
  };
</script>
