<template>
  <div
    class="alert--success form-message"
    :class="show"
    role="alert"
    aria-hidden="true"
  >
    <svg
      class="success-icon"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Success</title>
      <g transform="translate(1 1)" fill="none">
        <circle fill="#fff" cx="13.675" cy="13.218" r="13.065"/>
        <path
          d="M7.829 13.267l3.523 3.861c.166.182.437.179.602-.002l7.566-7.222"
          stroke="#00C8E6"
          stroke-width="2"
          stroke-linecap="round"
        />
      </g>
    </svg>
    <span
      id="success-alert-text"
      v-html="this.message"
    ></span>
  </div>
</template>

<script>
  export default {
    name: 'success-notification',
    props: ['success', 'controlled', 'withTranslation'],
    computed: {
      show() {
        if (this.controlled) {
          return { show: !!this.success };
        }

        return '';
      },
      message() {
        if (this.withTranslation) {
          return this.success;
        }

        return this.$i18n.t('alerts.success');
      },
    },
  };
</script>
