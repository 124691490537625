<template>
  <div class="lead page-navigation">
    <button
      id="first-page-button"
      aria-label="First Page"
      :class="[
        { 'button-inactive': isNotValidPageNumber },
        'nav-button', 'button-start'
      ]"
      @click="updatePage(1)"
    >
      <img
        id="first-page-icon"
        src="../../assets/images/svg/arrow-first.svg"
        :class="{ 'icon-inactive': isNotValidPageNumber }"
      >
    </button>
    <button
      aria-label="Get Previous Page"
      id="previous-page-button"
      :class="[
        { 'button-inactive': isNotValidPageNumber },
        'nav-button', 'button-start'
      ]"
      @click="updatePage(modelValue - 1)"
    >
      <img
        id="previous-page-icon"
        src="../../assets/images/svg/arrow-previous.svg"
        :class="{ 'icon-inactive': isNotValidPageNumber }"
      >
    </button>
    <span>{{ $t("table.page") }}</span>
    <input
      id="page-number-input"
      type="number"
      class="number-input navigation-input table-pagination-input"
      min="1"
      :max="totalPageCount"
      step="1"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @change="onPageNumberInputChange($event)"
    />
    <span>
      {{ $t("table.page_of") }} {{ totalPageCount }}
    </span>
    <button
      id="next-page-button"
      aria-label="Get Next Page"
      :class="[
        { 'button-inactive': isLastPageNumber },
        'nav-button', 'button-start'
      ]"
      @click="updatePage(modelValue + 1)"
    >
      <img
        id="next-page-icon"
        src="../../assets/images/svg/arrow-next.svg"
        :class="{ 'icon-inactive': isLastPageNumber }"
      >
    </button>
    <button
      aria-label="Get Last Page"
      id="last-page-button"
      :class="[
        { 'button-inactive': isLastPageNumber },
        'nav-button', 'button-start'
      ]"
      @click="updatePage(totalPageCount)"
    >
      <img
        id="last-page-icon"
        src="../../assets/images/svg/arrow-last.svg"
        :class="{ 'icon-inactive': isLastPageNumber }"
      >
    </button>
  </div>
</template>

<script>
  export default {
    name: 'table-pagination',
    // Value prop represents the current page (In table.vue we the v-model for this component is the page number)
    props: ['totalPageCount', 'modelValue'],
    emits: ['updatePage'],
    methods: {
      updatePage(pageNumber) {
        this.$emit('updatePage', pageNumber);
      },
      onPageNumberInputChange(event) {
        let nextPage;
        if (event.target.value === '') {
          nextPage = 1;
        } else if (this.modelValue < 1) {
          nextPage = 1;
        } else if (this.modelValue > this.totalPageCount) {
          nextPage = this.totalPageCount;
        }

        if (this.modelValue >= 1 && this.modelValue <= this.totalPageCount) {
          nextPage = Math.ceil(this.modelValue);
        }

        this.updatePage(nextPage);
      },
    },
    computed: {
      isNotValidPageNumber() {
        return this.modelValue <= 1;
      },
      isLastPageNumber() {
        return this.modelValue === this.totalPageCount;
      },
    },
  };
</script>

<style scoped>
  .page-navigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: 40px 0px;
  }

  .navigation-input {
    margin-right: 5px;
  }

  .nav-button {
    height: 30px;
    width: 30px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-inactive {
    pointer-events: none;
  }

  .icon-inactive {
     filter: invert(60%) sepia(6%) saturate(16%) hue-rotate(326deg) brightness(98%) contrast(92%);
  }

  .button-start {
    margin-right: 5px;
  }

   .button-previous {
    margin-right: 10px;
  }

   .button-next {
    margin-left: 10px;
  }

   .button-end {
    margin-left: 5px;
  }
</style>
