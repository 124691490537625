<template>
      <Modal v-on:close="$emit('close')" data-testid="master-switch-modal">
        <template v-slot:header>
      <h3>Are you sure you want to turn {{ isChecked ? 'on' : 'off' }} the master switch?</h3>
    </template>
    <template v-slot:body>
        Please make sure you checked all the changed inputs before turning {{ isChecked ? 'on' : 'off' }} the master switch as they might be impacted
    </template>
        <template v-slot:footer>
      <div class="footer">
        <button data-testid="proceed-button" class="button button--filled--blue" @click="handleMasterSwitch">
          Proceed
        </button>
        <button data-testid="cancel-button" class="button cancel" @click="closeModal">
          {{ $t('help_connect.cancel') }}
        </button>
      </div>
    </template>
      </Modal>

  </template>

  <script>
  import Modal from '../../common/components/Modal.vue';
  import config from '../../../config/index'

  export default {
    components: {
      Modal,
    },
    props: {
        isChecked: {
            type: Boolean
        }
    },
    emits: ['close','update:checked'],
    data() {
      return {
        showModal: false,
      };
    },
    methods: {
      async handleMasterSwitch() {
        const { API_URL } = config;
        const BEARER_TOKEN=this.getBearerTokenFromCookie();
        try {
          const response = await fetch(`${API_URL}customerservice/country-removal/status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${BEARER_TOKEN}`
          },
          body: JSON.stringify({
            isActive: this.isChecked
          })
        });

        if (!response.ok) {
          throw new Error('Failed to send status');
        }

        const data = await response.json();
        this.$emit('close');

        } catch (error) {
          console.log(error)
        }
      },
      getBearerTokenFromCookie(){
        const name = `accessToken=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for(let i = 0; i < cookieArray.length; i++) {
          let cookie = cookieArray[i];
          while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
          }
          if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
          }
        }
        return '';
        },
        closeModal(){
            this.$emit('close');
            this.$emit('update:checked', !this.isChecked);
        }
    },
  };
  </script>

<style scoped>
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.cancel {
  margin-left: 10px;
}
.text-input {
  width: 100%;
  padding: 10px;
  font-family: inherit;
  border: 1px solid #000;
  border-radius: 5px;
}

textarea {
  resize: none;
  height: 120px;
  font-family: inherit;
}

textarea.text-input-consent {
  height: 100px;
}

label {
  text-transform: capitalize;
}

h3 {
  padding: 0;
}

.tab-nav-item {
  width: 50px;
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-nav-item.is-active {
  color: #fff;
  background: #00c8e6;
}
</style>
