<template>
  <div class="entries-container">
    <div class="entries">
      <div class="header-item">
        {{ $t("table.header_entry_show") }}
        {{ rowsLength }}
        {{ $t("table.header_entry_of") }}
        {{ totalEntries }}
        {{ $t("table.header_entry_total") }}
      </div>
      <div class="separator"></div>
      <div class="header-item logs__table-count-select">
          <span>{{ $t("table.header_entry_per_page") }}</span>
          <input
            id="entry-number-input"
            type="number"
            class="number-input table-pagination-input"
            required
            min="1"
            max="100"
            step="1"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            @change="onEntryNumberInputChange($event)"
          />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'entries-input',
    props: ['modelValue', 'rowsLength', 'totalEntries'],
    emits: ['updateNumOfEntries'],
    data() {
      return {
        // Send this as a prop if necesary
        MAX_ENTRIES: 200,
      };
    },
    methods: {
      onEntryNumberInputChange(event) {
        let newEntries;
        if (event.target.value === '') {
          newEntries = 1;
        } else if (this.modelValue < 1) {
          newEntries = 1;
        } else if (this.modelValue > this.MAX_ENTRIES) {
          newEntries = this.MAX_ENTRIES;
        }

        if (this.modelValue >= 1 && this.modelValue <= this.MAX_ENTRIES) {
          newEntries = Math.ceil(this.modelValue);
        }

        this.$emit('updateNumOfEntries', newEntries, 1);
      },
    },
  };
</script>

<style scoped>
  .entries-container {
    margin-bottom: 0.8rem;
  }

  .entries {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .header-item {
    font-size: 1.125rem;
    font-weight: 300;
  }

  .separator {
    height: 30px;
    width: 2px;
    background-color: #2d2d37;
    margin: 0 20px;
  }
</style>
