<template>
  <div>
    <slot
      :title="$t('customer-service.page-title')"
      :withError="true"
      :withSuccess="true"
      :controlled="true"
      :error="alerts.error"
      :withTranslation="true"
      :success="alerts.success"
    ></slot>
    <div class="content-wrapper">
      <section class="content-section text-center">
        <h1>{{ $t('account-information.headline') }}</h1>
        <p class="lead">{{ $t('account-information.text1') }}</p>
      </section>
      <section class="landing__container__account content-section text-center">
        <div class="settings__forms">
          <account-information></account-information>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import accountInformation from '../components/AccountInformation/AccountInformation.vue';
  import auth from '../common/auth';

  export default {
    name: 'cobi',
    data() {
      return {
        user: auth.user,
        alerts: {
          error: null,
          success: null,
        },
      };
    },
    components: {
      'account-information': accountInformation,
    },
  };
</script>

<style scoped>
.landing__container__account {
  margin-top: 0px !important;
}

.landing__container__account {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 50px;
  width: 100%;
}

.landing__container__account a {
  color: #FA4B69 !important;
  float: right;
  margin-right: 0px !important;
}
.landing__container__account a:hover {
  color: white !important;
  background-color: #FA4B69 !important;
  border: #FA4B69 !important;
}

.content-section {
  padding-top: 50px;
}

h1,
p {
  text-align: center;
}

.lead {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: 300;
  padding-left: 200px;
  padding-right: 200px;
  text-align: center;
}

@media screen and (max-width:63.9375em) {
  .lead {
    padding-left: 30px;
    padding-right: 30px;
  }
}
</style>
