import { createRouter, createWebHistory } from 'vue-router';
import myRoutes from './routes';
import commonRoutes from '../common/router/routes';

export default createRouter({
  history: createWebHistory(),
  routes: commonRoutes.concat(myRoutes),
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});
