<template>
  <button
    :disabled='disabled'
    :id="id"
    type="submit"
    class="button settings__form__save-button spinning-button button--filled--blue"
    :class="{ loading: isLoading }"
    v-on:click.prevent="$emit('click')"
    v-if="isVisible"
  >
    <span class="button-label">
      <slot></slot>
    </span>
  </button>
</template>

<script>
  export default {
    props: ['disabled', 'id', 'isLoading', 'isVisible'],
  };
</script>
