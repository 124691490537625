// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import Header from './common/components/Header.vue';
import Footer from './common/components/Footer.vue';
import router from './router';

globalThis.__VUE_OPTIONS_API__ = true;
globalThis.__VUE_PROD_DEVTOOLS__ = false;

/* eslint-disable */
const messages = {
  en: require('./common/locale/en.json'),
  de: require('./common/locale/de.json'),
  fr: require('./common/locale/fr.json'),
};

export const i18n = createI18n({
  locale: 'en',               // set locale
  legacy: true,               // not use composition API
  fallbackLocale: 'en',       // set fallback locale
  warnHtmlInMessage: 'off',   // disable of the Detected HTML in message
  escapeParameterHtml: true,  // interpolation parameters are escaped before the message is translated
  messages,                   // set locale messages
});

const app = createApp(App);

app.component('cobi-header', Header);
app.component('cobi-footer', Footer);

app.use(i18n);
app.use(router);

// TODO: replace this with the new composition api
app.directive('click-outside', {
  mounted(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

app.mount('#app');

export default app;
