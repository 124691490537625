<template>
  <div>
    <div class="container">
      <div v-for="lang in languages" :key="lang" class="language-item" :data-lang="lang">
        <div class="language">
          {{ lang }}
        </div>
        <div
          :data-checked="hasAllTexts(lang)"
          class="check-mark"
          @click="$emit('onEditLanguage', lang)"
          :data-testid="`${lang}-${countryCode}`"
        >
          <template v-if="hasAllTexts(lang)">
            <img src="../../common/assets/images/svg/checked.svg" />
          </template>
          <template v-else>
            <img src="../../common/assets/images/svg/unchecked.svg" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      texts: Object,
      countryCode: String,
    },
    computed: {
      languages() {
        return Object.keys(this.texts);
      },
    },
    methods: {
      hasAllTexts(lang) {
        const allTexts = this.texts[lang];
        // Button text and description should be mandatory
        if(allTexts.text !== ''  && allTexts.button !== '') {
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>

<style scoped>
.container {
  height: 100%;
  width: 100%;
  max-width: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.language-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
}
.language-item .check-mark {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-left: 4px;
}
</style>
