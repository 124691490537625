<template>
  <div v-show="selected">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      name: { required: true },
      selected: { default: false },
    },
  };
</script>
