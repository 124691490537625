<template>
  <div
    class="alert--error form-message error"
    aria-hidden="true"
    :class="show"
    role="alert"
  >
    <svg
      class="success-icon"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Error</title>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-569.000000, -147.000000)" fill="#FFFFFF">
          <path
            d="M584.387468,163.494 C584.94336,163.494 585.394,163.037003 585.394,162.485559 L585.394,156.002441 C585.394,155.445494 584.94606,154.994 584.387468,154.994 L583.612532,154.994 C583.05664,154.994 582.606,155.450997 582.606,156.002441 L582.606,162.485559 C582.606,163.042506 583.05394,163.494 583.612532,163.494 L584.387468,163.494 Z M584.387468,169.206 C584.94336,169.206 585.394,168.75975 585.394,168.20711 L585.394,167.34889 C585.394,166.797218 584.94606,166.35 584.387468,166.35 L583.612532,166.35 C583.05664,166.35 582.606,166.79625 582.606,167.34889 L582.606,168.20711 C582.606,168.758782 583.05394,169.206 583.612532,169.206 L584.387468,169.206 Z M584,147.956 C586.584013,147.956 588.975322,148.601994 591.174,149.894 C593.304677,151.118006 594.981994,152.795323 596.206,154.926 C597.498006,157.124678 598.144,159.515987 598.144,162.1 C598.144,164.684013 597.498006,167.075322 596.206,169.274 C594.981994,171.404677 593.304677,173.081994 591.174,174.306 C588.975322,175.598006 586.584013,176.244 584,176.244 C581.415987,176.244 579.024678,175.598006 576.826,174.306 C574.695323,173.059327 573.018006,171.370677 571.794,169.24 C570.501994,167.041322 569.856,164.661346 569.856,162.1 C569.856,159.538654 570.501994,157.158678 571.794,154.96 C573.040673,152.829323 574.729323,151.140673 576.86,149.894 C579.058678,148.601994 581.438654,147.956 584,147.956 Z"
            id="error---material"
          ></path>
        </g>
      </g>
    </svg>
    <span
      id="error-alert-text"
      v-html="this.message"
    ></span>
  </div>
</template>

<script>
  export default {
    name: 'error-notification',
    props: ['error', 'controlled', 'withTranslation'],
    computed: {
      show() {
        if (this.controlled) {
          return { show: !!this.error };
        }

        return '';
      },
      message() {
        if (this.withTranslation) {
          return this.error;
        }

        if (this.$i18n.t(`login.bosch_id.errors.${this.error}`) !== `login.bosch_id.errors.${this.error}`) {
          return this.$i18n.t(`login.bosch_id.errors.${this.error}`);
        }

        return this.$i18n.t('alerts.error');
      },
    },
  };
</script>
